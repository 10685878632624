import {EnvironmentType} from '../enums'

export const getBNBPoolAddress = () => {
  switch (process.env.REACT_APP_TYPE) {
  case EnvironmentType.DEV:
    return '0xfBF86b9613E8d4FF7e068bD8d2Bf212e9574e080'
  case EnvironmentType.STAGE:
    return '0xA8ba346DFE07cA1A843fC7e9F8153252Aa999F48'
  case EnvironmentType.PROD:
    return '0x9f8a13d31a2d2fe771c05b38d344719641647443'
  default:
    return ''
  }
}
