import {
  BenefitsBlock,
  InstructionBlock,
  PlatformsBlock,
  StatisticsBlock,
  WelcomeBlock,
} from './components'
import './index.scss'

const MainPageModule = () => {
  return (
    <div className="main-page">
      <WelcomeBlock />
      <StatisticsBlock />
      <PlatformsBlock />
      <BenefitsBlock />
      <InstructionBlock />
    </div>
  )
}

export default MainPageModule
