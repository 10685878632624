import { LaunchAppBtn } from '../../../../components'
import './index.scss'
import {
  FTMIcon, USDCIcon,
  WBTCIcon, WETHIcon,
  DAIIcon,
} from '../../../../components/Icons'
import useMobile from '../../../../hooks/useMobile'

const WelcomeBlock = () => {
  const { windowWidth } = useMobile()
  
  return (
    <div className="welcome-block">
      {windowWidth >= 892 && (
        <>
          <img
            src={require('../../../../assets/images/usdv-banner.png')}
            alt="usdv"
            className="usdv-banner"
          />
          <img
            src={require('../../../../assets/images/welcome-banner.png')}
            alt="welcome"
            className="welcome-banner"
          />
        </>
      )}
      <div className="content">
        <div className="title">
          USDV Overcollateralized stablecoin
        </div>
        <div className="description">
          Borrow USDV against a variety of the most prominent tokens
        </div>
        <div className="icons">
          <WETHIcon />
          <DAIIcon />
          <USDCIcon />
          <WBTCIcon />
          <FTMIcon />
        </div>
        <LaunchAppBtn />
      </div>
    </div>
  )
}

export default WelcomeBlock
