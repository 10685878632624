import './index.scss'

const BetaMessage = () => {
  return (
      <div className="beta-message">
          <div className="title">
              Beta is over! Main launch is coming soon
          </div>
          <div className="description">
              The beta phase of USDV has reached its conclusion.
              We extend our sincerest gratitude to all participants whose invaluable feedback and insights have been
              instrumental in refining our platform.
              The beta testing period has provided us with crucial data and user experiences, which will drive
              continuous improvements and enhancements to USDV project.
          </div>
          <div className="contacts">
              While the beta is now complete, we encourage you to sharing your feedback or issues with us at
              team@usdv.com
          </div>
      </div>
  )
}

export default BetaMessage
