import Icon, { TIconProps } from '../Icon'

const GreyWBTCIcon = (props: TIconProps) => {
  return (
    <Icon iconName="grey-wbtc-icon" { ...props }>
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.44797 28.3831L5.44806 28.3828C9.65328 11.5058 26.7448 1.23515 43.6164 5.44222L43.6165 5.44224C60.4922
          9.64936 70.7613 26.7438 66.5541 43.62C62.3469 60.4948 45.2555 70.7647 28.3785 66.5566C11.5088 62.3495
          1.23885 45.257 5.44797 28.3831Z"
          fill="#4B65AE"
          stroke="#1C2B54"
          strokeWidth="3"
        />
        <path
          d="M50.5507 31.2984C51.2076 26.9073 47.8643 24.5468 43.2928 22.9721L44.7757 17.0238L41.155 16.1215L39.7112
          21.913C38.7594 21.6758 37.7818 21.452 36.8103 21.2303L38.2644 15.4006L34.6457 14.4983L33.1618 20.4445C32.3739
          20.265 31.6004 20.0877 30.8497 19.901L30.8538 19.8825L25.8605 18.6357L24.8973 22.5029C24.8973 22.5029 27.5837
          23.1185 27.527 23.1567C28.9934 23.5228 29.2585 24.4932 29.2141 25.2625L27.5249 32.0388C27.626 32.0646 27.757
          32.1017 27.9014 32.1595C27.7807 32.1296 27.6518 32.0966 27.5188 32.0646L25.151 41.5573C24.9716 42.0028
          24.5168 42.671 23.4917 42.4174C23.5278 42.4699 20.86 41.7604 20.86 41.7604L19.0625 45.905L23.7743
          47.0796C24.6508 47.2993 25.5099 47.5293 26.3555 47.7458L24.8571 53.7621L28.4737 54.6645L29.9577
          48.7121C30.9456 48.9802 31.9047 49.2277 32.8431 49.4608L31.3643 55.3853L34.985 56.2877L36.4834
          50.2827C42.6575 51.4511 47.3002 50.9798 49.2544 45.3956C50.8292 40.8993 49.1761 38.3057 45.9276
          36.6145C48.2933 36.069 50.0753 34.5128 50.5507 31.2984ZM42.278 42.8989C41.1591 47.3952 33.5887 44.9645
          31.1343 44.3551L33.1226 36.3845C35.577 36.9971 43.4475 38.2098 42.278 42.8989ZM43.398 31.2334C42.377
          35.3234 36.0761 33.2454 34.0321 32.736L35.8348 25.5069C37.8787 26.0163 44.4612 26.9671 43.398 31.2334Z"
          fill="#1C2B54"
        />
      </svg>
    </Icon>
  )
}

export default GreyWBTCIcon
