import classNames from 'classnames'

import {LaunchAppBtn, NavActions, Socials} from '../index'
import './index.scss'

interface IBurgerMenu {
  burgerVisible: boolean,
  onClose?: () => void,
}

const BurgerMenu = ({ burgerVisible, onClose }: IBurgerMenu) => {
  return (
    <div 
      className={classNames(
        'burger-menu',
        burgerVisible ? 'visible' : 'hidden',
      )}
    >
      <NavActions onClick={onClose} />
      {/*<LaunchAppBtn />*/}
      <Socials />
    </div>
  )
}

export default BurgerMenu
