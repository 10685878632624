import Icon, {TIconProps} from '../Icon'

const NoticeIcon = (props: TIconProps) => {
  return (
    <Icon iconName="percentage-icon" {...props}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.6815 5.66663H16.3182C9.64484 5.66663 5.6665 9.64496 5.6665 16.3183V31.6633C5.6665 38.355 9.64484
          42.3333 16.3182 42.3333H31.6632C38.3365 42.3333 42.3148 38.355 42.3148 31.6816V16.3183C42.3332 9.64496
          38.3548 5.66663 31.6815 5.66663ZM16.2082 18.7566C16.2082 17.2716 17.4182 16.0433 18.9215 16.0433C20.4065
          16.0433 21.6348 17.2533 21.6348 18.7566C21.6348 20.2416 20.4248 21.47 18.9215 21.47C17.4182 21.4516 16.2082
          20.2416 16.2082 18.7566ZM18.1698 31.37C17.8215 31.37 17.4732 31.2416 17.1982 30.9666C16.6665 30.435 16.6665
          29.5733 17.1982 29.0233L29.2065 17.015C29.7382 16.4833 30.6182 16.4833 31.1498 17.015C31.6815 17.5466
          31.6815 18.4266 31.1498 18.9583L19.1415 30.9666C18.8665 31.2416 18.5182 31.37 18.1698 31.37ZM30.9115
          31.9566C29.4265 31.9566 28.1982 30.7466 28.1982 29.2433C28.1982 27.7583 29.4082 26.53 30.9115 26.53C32.3965
          26.53 33.6248 27.74 33.6248 29.2433C33.6248 30.7466 32.4148 31.9566 30.9115 31.9566Z"
          fill="#4B65AE"
        />
      </svg>
    </Icon>
  )
}

export default NoticeIcon
