import { GreyVaultIcon, PercentageIcon} from '../../../../components/Icons'
import { ExampleNotification } from '../../../../components'
import './index.scss'

const CommissionBlock = () => {
  return (
    <div className="commission-block">
      <div className="block-title">
        Commission
      </div>

      <div className="repayment-fees">
        <div className="title-section">
          <GreyVaultIcon/>
          <div className="title">
            Repayment Fees
          </div>
        </div>

        <div className="description">
          When a user unlocks their collateral, they pay the repayment fee of 1% in a collateral token.
        </div>

        <ExampleNotification>
          <div className="text">
            A user has a collateral in BTC worth $900 and they mint 300 USDV,
            which matches the collateral ratio of 300%. If they decide to repay 100 USDV,
            they will pay a commission fee of $1 in BTC (100 USDV * 1% fee).
          </div>
        </ExampleNotification>
      </div>

      <div className="swap-fees">
        <div className="title-section">
          <PercentageIcon/>
          <div className="title">
            Swap Fees
          </div>
        </div>

        <div className="description">
          The swap protocol allows users to exchange USDV for DAI or USDC at a fixed rate. This is another way of
          getting USDV along with using a vault.
        </div>

        <div className="description">
          The swap fee is 0.5% when exchanging USDV.
        </div>
      </div>
    </div>
  )
}

export default CommissionBlock
