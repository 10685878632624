import { getLink } from '../../../../../../utils/getLink'
import {
  GreyETHIcon,
  GreyFTMIcon,
  GreyUSDVIcon,
  GreyVaultIcon,
} from '../../../../../../components/Icons'
import './index.scss'

const GenerationStepsMobile = () => {
  return (
    <div className="generation-steps-mobile">
      <div className="generation-step">
        <div className="title">
          <span>
            1. Create a vault
          </span>
          <div className="icons vault">
            <GreyVaultIcon />
          </div>
        </div>
        <div className="description">
          User creates a vault at
          <a
            href={getLink()}
            target="_blank"
            rel="noreferrer"
          >
            app.usdv.com
          </a>
        </div>
      </div>
      <div className="generation-step">
        <div className="title">
          <span>
            2. Deposit a collateral
          </span>
          <div className="icons">
            <GreyFTMIcon />
            <GreyETHIcon />
          </div>
        </div>
        <div className="description">
          User deposits X ETH in a vault as collateral.
          For example, a user deposits 1 ETH, which at a current rate is worth $1,200
        </div>
      </div>
      <div className="generation-step">
        <div className="title">
          <span>
            3. Mint USDV
          </span>
          <div className="icons">
            <GreyUSDVIcon />
          </div>
        </div>
        <div className="description">
          Users can mint X USDV with a 130% collateral ratio.
          If a user deposits 1 ETH at a rate of $1,200,
          they can borrow up to $1,200 / 130% = 923.076923 USDV
        </div>
      </div>
    </div>
  )
}

export default GenerationStepsMobile
