import { Abi } from 'abitype'

import { PoolConfig, StrategyVaultsType } from '../../types'
import { getUSDVAddress } from '../../../../utils/getUSDVAddress'
import { NetworkType } from '../../../../enums'
import { ERC20 } from '../../abi/ERC20'
import { StrategyVault } from '../../abi/StrategyVault'
import { getWBTCPoolStrategyAddress } from '../../../../utils/getWBTCPoolStrategyAddress'

export const WBTCxUSDV_STRATEGY_YEARN: PoolConfig = {
  collateral: 'wBTC',
  contractAddress: getWBTCPoolStrategyAddress(),
  network: NetworkType.ethereum,
  abi: StrategyVault as Abi,
  decimals: 8,
  erc20Address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  erc20Abi: ERC20 as Abi,
  stablecoinAddress: getUSDVAddress(NetworkType.ethereum),
  stablecoinAbi: ERC20 as Abi,
  strategy: true,
  strategyType: StrategyVaultsType.Yearn,
}
