import Icon, { TIconProps } from '../Icon'

const DocumentIcon = (props: TIconProps) => {
  return (
    <Icon iconName="document-icon" { ...props }>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 1.5C16.5523 1.5 17 1.94772 17 2.5V17.5C17 18.0523 16.5523 18.5 16 18.5H5C4.44772 18.5 4 18.0523 4
          17.5V6.61151C4 6.36114 4.09392 6.11987 4.26321 5.9354L8.03612 1.82388C8.22552 1.61749 8.4928 1.5 8.77292
          1.5H16ZM13.5333 9.05556H7.46667V10.9444H13.5333V9.05556ZM13.5333 12.8333H7.46667V14.7222H13.5333V12.8333Z"
          fill="white"
        />
      </svg>
    </Icon>
  )
}

export default DocumentIcon
