import './index.scss'

const BenefitsBlock = () => {
  return (
    <div className="benefits-block">
      <div className="piggy-bank-image" />
      <div className="benefits">
        <div className="subtitle">
          Our benefits
        </div>
        <div className="benefit">
          <span>Zero-interest borrowing</span>
          <span>Borrow stablecoins with collateral at 0% interest</span>
        </div>
        <div className="benefit">
          <span>Stake</span>
          <span>Stake LP token to get USDV</span>
        </div>
        <div className="benefit">
          <span>Swap</span>
          <span>Exchange stablecoins directly for USDV</span>
        </div>
      </div>
    </div>
  )
}

export default BenefitsBlock
