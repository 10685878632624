import Icon, { TIconProps } from '../Icon'

const SwapIcon = (props: TIconProps) => {
  return (
    <Icon iconName="swap-icon" { ...props }>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.9">
          <path
            d="M28.3334 19.9867C28.3334 20.24 28.2401 20.4933 28.0401 20.6933L21.3601 27.3733C20.9734 27.76 20.3334
            27.76 19.9467 27.3733C19.5601 26.9867 19.5601 26.3467 19.9467 25.96L26.6267 19.28C27.0134 18.8933 27.6534
            18.8933 28.0401 19.28C28.2267 19.48 28.3334 19.7333 28.3334 19.9867Z"
            fill="white"
          />
          <path
            d="M28.3332 19.9866C28.3332 20.5332 27.8798 20.9866 27.3332 20.9866H4.6665C4.11984 20.9866 3.6665 20.5332
            3.6665 19.9866C3.6665 19.4399 4.11984 18.9866 4.6665 18.9866H27.3332C27.8798 18.9866 28.3332 19.4399
            28.3332 19.9866Z"
            fill="white"
          />
          <path
            d="M12.3468 5.31992C12.3468 5.57325 12.2535 5.82658 12.0535 6.02658L5.3735 12.7066C4.98683 13.0932 4.34683
            13.0932 3.96017 12.7066C3.5735 12.3199 3.5735 11.6799 3.96017 11.2932L10.6402 4.61325C11.0268 4.22658
            11.6668 4.22658 12.0535 4.61325C12.2535 4.81325 12.3468 5.06658 12.3468 5.31992Z"
            fill="white"
          />
          <path
            d="M28.3332 12.0134C28.3332 12.5601 27.8798 13.0134 27.3332 13.0134L4.6665 13.0134C4.11984 13.0134 3.6665
             12.5601 3.6665 12.0134C3.6665 11.4668 4.11984 11.0134 4.6665 11.0134L27.3332 11.0134C27.8798 11.0134
             28.3332 11.4534 28.3332 12.0134Z"
            fill="white"
          />
        </g>
      </svg>
    </Icon>
  )
}

export default SwapIcon
