import Icon, { TIconProps } from '../Icon'

const BOOIcon = (props: TIconProps) => {
  return (
    <Icon iconName="boo-token-icon" {...props}>
      <svg
        viewBox="0 0 64 64"
        fill="none"
        width="32px"
        xmlns="http://www.w3.org/2000/svg"
        className="sc-bcXHqe gyiUSo"
      >
        <path d="M0 32a32 32 0 1064 0 32 32 0 00-64 0z" fill="#121122" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.655 23.892c-.391 2.517.07 5.107.548 7.596.184.398.588.665 1.086.837a16.191 16.191 0 00-1.582 6.964c0 10.416 10.083 18.867 22.52 18.867 12.438 0 22.529-8.431 22.529-18.867a16.2 16.2 0 00-1.613-7.03c.416-.172.748-.421.91-.771.479-2.489.937-5.08.546-7.596-.366-3.378-1.525-4.41-5.006-3.544-1.77.412-4.105 1.457-5.9 2.686-3.359-1.667-7.28-2.623-11.465-2.623-4.235 0-8.196.98-11.58 2.683-1.805-1.255-4.187-2.328-5.985-2.746-3.483-.865-4.64.166-5.008 3.544zm13.113 14.892c-2.64-1.164-7.152-.87-7.152-.87s.212 4.248 2.802 5.69c3.072 1.708 8.784.862 8.784.862s-.486-3.95-4.434-5.69v.008zm14.668 5.689s5.712.846 8.784-.862c2.59-1.442 2.802-5.69 2.802-5.69s-4.512-.294-7.152.87c-3.948 1.725-4.434 5.666-4.434 5.666v.016zM31.1 47.467c0 .313.582.567 1.298.567.717 0 1.298-.254 1.298-.567 0-.313-.581-.566-1.298-.566-.716 0-1.298.244-1.298.566z"
          fill="#F2F4F8"></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.893 44.852a18.543 18.543 0 00-6.606-1.245h.004c-.573 0-.573-.889 0-.889A19.24 19.24 0 0158.133 44a.453.453 0 01.308.54.448.448 0 01-.548.312zM6.47 44.02a19.26 19.26 0 016.845-1.282v.005c.572 0 .57.889 0 .889-2.26.008-4.5.43-6.608 1.244a.448.448 0 01-.546-.31.455.455 0 01.31-.547zm4.532 2.547A12.114 12.114 0 0114.378 46c.57.003.568.889-.003.889a11.174 11.174 0 00-3.08.517c-.929.272-1.99.656-2.668 1.284-.42.39-1.048-.238-.627-.628.823-.76 1.955-1.18 3-1.495zm4.391 1.653c-.891.382-1.749.84-2.562 1.367a7.982 7.982 0 00-2.133 1.922.446.446 0 00.158.608.457.457 0 00.61-.158c.5-.69 1.216-1.225 1.925-1.682a17.31 17.31 0 012.443-1.289.448.448 0 00.16-.608.457.457 0 00-.608-.16h.007zm34.83-1.349c1.046.026 2.084.2 3.081.516.516.146 1.019.332 1.506.556.42.184.81.43 1.157.729.42.388 1.05-.238.63-.63-.818-.759-1.944-1.18-2.996-1.495a12.07 12.07 0 00-3.378-.565c-.572 0-.574.89 0 .89zm.981 3.38a17.75 17.75 0 00-2.405-1.245v-.009a.45.45 0 01-.16-.608.46.46 0 01.61-.158c1.732.756 3.554 1.726 4.686 3.289a.448.448 0 01-.158.608.455.455 0 01-.608-.16c-.512-.706-1.236-1.253-1.965-1.717z"
          fill="#F2F4F8" />
        <path
          d="M3.092 33.753c.41-.64 4.02-2.566 8.485-3.709a52.475 52.475 0 013.555-7.93c-.017 0 3.572-6.443 3.556-6.446a20.3 20.3 0 01-2.955-1.656 2.562 2.562 0 01-.428-.417.984.984 0 01-.142-.521c0-.15.211-.416.305-.534.24-.282.506-.543.793-.778a21.002 21.002 0 012.153-1.547c.356-.235.726-.464 1.09-.695 2.56-1.63 3.963-2.857 6.307-4.204 2.24-1.284 3.329-2.075 5.926-1.942 3.264 0 11.378 10.336 15.036 15.662 2.194 3.2 5.138 8.962 6.222 11.13.26.065.495.127.712.191 3.328 1 6.592 2.464 7.39 3.396.711.828-2.397 1.198-5.46 1.731-3.063.534-13.454.799-23.44.866-9.986.068-21.64-.267-24.177-.666-2.537-.4-5.439-1.133-4.928-1.931z"
          fill="#6665DD" />
        <path
          d="M50.212 24.825s-6.578-1.648-18.168-1.648c-12.444 0-18.437 1.92-18.437 1.92s-.711 1.351-1.02 2.133c-.31.782-1.01 2.814-1.01 2.814s9.054-1.62 20.642-1.62c6.977.028 13.941.617 20.825 1.76 0 0-1.051-2.46-1.68-3.508a65.33 65.33 0 00-1.152-1.851z"
          fill="#FF99A5" />
        <path
          d="M11.063 10.92c.754-.11 1.778 2.198 1.778 2.198s2.371-.638 2.741 0c.37.638-1.667 2.17-1.667 2.17s1.23 2.164.84 2.614c-.389.45-2.583-.915-2.583-.915s-1.673 1.884-2.218 1.6c-.546-.285.073-2.74.073-2.74s-2.367-1.006-2.205-1.604c.162-.597 2.628-.778 2.628-.778s-.139-2.434.613-2.544z"
          fill="#FFE600" />
      </svg>
    </Icon>
  )
}

export default BOOIcon
