import Icon, { TIconProps } from '../Icon'

const TwitterIcon = (props: TIconProps) => {
  return (
    <Icon iconName="twitter-icon" { ...props }>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.75 4.75227C18.1049 5.02919 17.4134 5.21513 16.6864 5.29952C17.4284 4.86963 17.9985 4.18918 18.2658
          3.37951C17.573 3.77643 16.8024 4.06523 15.9854 4.22083C15.3307 3.54566 14.3978 3.125 13.3654 3.125C11.3823
          3.125 9.77428 4.67842 9.77428 6.5958C9.77428 6.86745 9.80701 7.1325 9.86839 7.38701C7.38915 7.2665 5.14432
          6.29224 3.43969 4.7614C2.92981 4.30351 2.09835 4.38906 2.01043 5.0687C1.99192 5.21172 1.98241 5.35741
          1.98241 5.50524C1.98241 6.7092 2.61525 7.77207 3.57951 8.39581C2.99032 8.37735 2.43658 8.22043 1.9524
          7.96064C1.9524 7.97515 1.9524 7.98965 1.9524 8.00416C1.9524 9.68681 3.19081 11.0886 4.83156 11.409C4.53151
          11.4881 4.21372 11.5303 3.88639 11.5303C3.58175 11.5303 3.33618 11.8228 3.48788 12.087C4.09096 13.1372
          5.24062 13.8556 6.56369 13.8789C5.3362 14.8099 3.78819 15.3638 2.10516 15.3638C1.90099 15.3638 1.81416
          15.6435 1.99593 15.7365C3.41598 16.4628 5.03416 16.875 6.75327 16.875C13.3572 16.875 16.9688 11.5857 16.9688
          6.99932C16.9688 6.84899 16.9647 6.69734 16.9592 6.54964C17.6603 6.06041 18.2685 5.44853 18.75 4.75227Z"
          fill="white"
        />
      </svg>
    </Icon>
  )
}

export default TwitterIcon
