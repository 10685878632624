import Icon, { TIconProps } from '../Icon'

const SocialsArrowIcon = (props: TIconProps) => {
  return (
    <Icon iconName="socials-arrow-icon" { ...props }>
      <svg
        width="16" height="16"
        viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10.7406 11.0461C10.3093 11.0461 9.9516 10.6884 9.9516 10.2571V6.58569H6.28016C5.84885 6.58569 5.49117 6.22801 5.49117 5.7967C5.49117 5.36538 5.84885 5.0077 6.28016 5.0077H10.7406C11.1719 5.0077 11.5296 5.36538 11.5296 5.7967V10.2571C11.5296 10.6884 11.1719 11.0461 10.7406 11.0461Z" fill="#90A7E8"/>
        <path d="M4.78636 12.5296C4.58648 12.5296 4.38661 12.456 4.22881 12.2982C3.92373 11.9931 3.92373 11.4881 4.22881 11.1831L10.1831 5.22881C10.4881 4.92373 10.9931 4.92373 11.2982 5.22881C11.6032 5.53388 11.6032 6.03885 11.2982 6.34393L5.34391 12.2982C5.19663 12.456 4.98624 12.5296 4.78636 12.5296Z" fill="#90A7E8"/>
      </svg>

    </Icon>
  )
}

export default SocialsArrowIcon
