import { useCallback, useEffect } from 'react'

import { useStatisticsContext } from '../../providers/statisticsContext'
import { ZeroInterestBlock } from '../index'

import { BetaMessage, ChartData } from './components'
import './index.scss'

const StatisticsBlock = () => {
  const { initPools, setMyChart, myChart } = useStatisticsContext()

  const resizeChart = useCallback(() => {
    if (myChart) {
      const chart = document.getElementById('chart')?.firstChild as HTMLDivElement
      const svg = chart.firstChild as unknown as HTMLOrSVGScriptElement

      if (window.innerWidth < 600 && chart.clientWidth === 726) {
        chart.style.width = '466px'
        chart.style.height = '466px'
        svg.style.width = '466px'
        svg.style.height = '466px'

        myChart?.setOption({
          series: [
            {
              width: '466px',
              height: '466px',
            },
          ],
        })
      }

      if (window.innerWidth > 599 && chart.clientWidth === 466) {
        chart.style.width = '726px'
        chart.style.height = '726px'
        svg.style.width = '726px'
        svg.style.height = '726px'

        myChart?.setOption({
          series: [
            {
              width: '726px',
              height: '726px',
            },
          ],
        })
      }
    }
  }, [myChart])

  useEffect(() => {

    (async () => await initPools())()

    return () => setMyChart(undefined)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resizeChart)
    return () => window.removeEventListener('resize', resizeChart)
  }, [resizeChart])
  
  return (
    <div className="statistics-block">
      <BetaMessage />
      <ZeroInterestBlock />
    </div>
  )
}

export default StatisticsBlock
