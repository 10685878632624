import Icon, { TIconProps } from '../Icon'

const FTMIcon = (props: TIconProps) => {
  return (
    <Icon iconName="ftm-icon" { ...props }>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4571_63859)">
          <path
            d="M16 32C24.8365 32 32 24.8366 32 16C32 7.16345 24.8365 0 16 0C7.16343 0 -1.52588e-05 7.16345 -1.52588e-05 16C-1.52588e-05 24.8366 7.16343 32 16 32Z"
            fill="#13B5EC"/>
          <path fillRule="evenodd" clipRule="evenodd"
                d="M17.2 12.902L20.8 10.802V15.002L17.2 12.902ZM20.8 21.902L16 24.702L11.2 21.902V17.002L16 19.802L20.8 17.002V21.902ZM11.2 10.802L14.8 12.902L11.2 15.002V10.802ZM16.6 13.902L20.2 16.002L16.6 18.102V13.902ZM15.4 18.102L11.8 16.002L15.4 13.902V18.102ZM20.2 9.80195L16 12.202L11.8 9.80195L16 7.30195L20.2 9.80195ZM10 9.40195V22.502L16 25.902L22 22.502V9.40195L16 6.00195L10 9.40195Z"
                fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_4571_63859">
            <rect width="32" height="32" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    </Icon>
  )
}

export default FTMIcon
