import Icon, { TIconProps } from '../Icon'

const RedArrowRangeIcon = (props: TIconProps) => {
  return (
    <Icon iconName="red-arrow-icon" { ...props }>
      <svg
        width="23"
        height="170"
        viewBox="0 0 23 170"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 169L11.7735 159L0.226498 159L6 169ZM6.00001 1L0.226504 11L11.7735 11L6.00001 1ZM7 160L7.00001
          10L5.00001 10L5 160L7 160Z"
          fill="#FC5353"
        />
        <circle
          cx="10"
          cy="1"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="14"
          cy="1"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="18"
          cy="1"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="22"
          cy="1"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="10"
          cy="169"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="14"
          cy="169"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="18"
          cy="169"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="22"
          cy="169"
          r="1"
          fill="#FC5353"
        />
      </svg>
    </Icon>
  )
}

export default RedArrowRangeIcon
