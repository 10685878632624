import { useState } from 'react'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CreateVaultBtn } from '../../../../components'
import { ArrowIcon, NoticeIcon } from '../../../../components/Icons'
import useMobile from '../../../../hooks/useMobile'
import './index.scss'
import 'swiper/css'
import 'swiper/css/navigation'

const InteractingBlock = () => {
  const [slide, setSlide] = useState(1)
  const { windowWidth } = useMobile()

  const Sliders = [
    {
      name: 'Create a vault',
      description: 'Create a vault at the USDV vault portal',
      image: 'first',
    },
    {
      name: 'Collateralise a vault',
      description: 'Deposit funds to the vault you created to generate USDV',
      image: 'second',
    },
    {
      name: 'Generate USDV from the collateralized vault',
      description: 'Mint USDV based on available collateral',
      image: 'third',
    },
    {
      name: 'Pay back the loan',
      description: 'To redeem your collateral the loan must be paid back in USDV',
      image: 'fourth',
    },
    {
      name: 'Withdraw collateral',
      description: 'Withdraw part of your collateral',
      image: 'fifth',
    },
  ]
  
  return (
    <div className="interacting-block">
      <div className="block-title">
        Interacting with USDV protocol
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        loop={true}
        modules={[Navigation]}
        navigation={{
          nextEl: '.next-slide-btn',
          prevEl: '.prev-slide-btn',
        }}
        className="my-swiper"
        onActiveIndexChange={(data) => {
          setSlide(data.realIndex + 1)
        }}
      >
        {Sliders.map((slider, index) => (
          <SwiperSlide key={index}>
            <div className="list-item">
              {windowWidth > 599 && (
                <div className="step">
                  {index + 1}
                </div>
              )}
              <div className="description">
                <span>
                  {windowWidth < 600 && `${index + 1}. `}{slider.name}
                </span>
                <span className="grey-20-text">
                  {slider.description}
                </span>
              </div>
            </div>
            <div className={`slider-image ${slider.image}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="actions">
        <CreateVaultBtn />
        <div className="slider-navigation">
          <div className="prev-slide-btn">
            <ArrowIcon />
          </div>
          <div className="current-slide">
            {slide} / 5
          </div>
          <div className="next-slide-btn">
            <ArrowIcon />
          </div>
        </div>
      </div>
      <div className="notice">
        <div className="title">
          <NoticeIcon />
          Please note!
        </div>
        <div className="content">
          Each deposited collateral asset requires its own vault.
          Therefore, some users will own multiple vaults with different
          types of collateral and levels of collateralization
        </div>
      </div>
    </div>
  )
}

export default InteractingBlock
