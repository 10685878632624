import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { WagmiConfig } from 'wagmi'

import { RootLayout } from '../index'
import MainPage from '../../pages/MainPage'
import HowItWorks from '../../pages/HowItWorks'
import { config } from '../../services/web3Client'
import { StatisticsProvider } from '../../modules/MainPageModule/providers/statisticsContext'
import './App.scss'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<MainPage />} />
        <Route path="how-it-works" element={<HowItWorks />} />
      </Route>
      <Route path="*" element={<div>Not found</div>} />
    </>,
  ),
)

const App = () => {
  return (
    <WagmiConfig config={config}>
      <StatisticsProvider>
        <RouterProvider router={router}/>
      </StatisticsProvider>
    </WagmiConfig>
  )
}

export default App
