import { Abi } from 'abitype'

import { PoolConfig, StrategyVaultsType } from '../../types'
import { NetworkType } from '../../../../enums'
import { ERC20 } from '../../abi/ERC20'
import { StrategyVault } from '../../abi/StrategyVault'
import { getFTMPoolStrategyAddress } from '../../../../utils/getFTMPoolStrategyAddress'
import { getUSDVAddress } from '../../../../utils/getUSDVAddress'

export const WFTMxUSDV_STRATEGY_YEARN: PoolConfig = {
  collateral: 'FTM',
  contractAddress: getFTMPoolStrategyAddress(),
  abi: StrategyVault as Abi,
  network: NetworkType.fantom,
  decimals: 18,
  erc20Address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
  erc20Abi: ERC20 as Abi,
  stablecoinAddress: getUSDVAddress(NetworkType.fantom),
  stablecoinAbi: ERC20 as Abi,
  strategy: true,
  strategyType: StrategyVaultsType.Yearn,
}
