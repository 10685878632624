import Icon, { TIconProps } from '../Icon'

const USDCIcon = (props: TIconProps) => {
  return (
    <Icon iconName="usdc-icon" { ...props }>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 37.8574C29.8959 37.8574 37.8571 29.8961 37.8571 20.0002C37.8571 10.1043 29.8959 2.14307 20
          2.14307C10.1041 2.14307 2.14282 10.1043 2.14282 20.0002C2.14282 29.8961 10.1041 37.8574 20 37.8574Z"
          fill="#2775CA"
        />
        <path
          d="M24.9106 22.8274C24.9106 20.2233 23.3481 19.3304 20.2231 18.9584C17.991 18.6608 17.5445 18.0656 17.5445
          17.0238C17.5445 15.982 18.2886 15.3125 19.7767 15.3125C21.116 15.3125 21.8601 15.759 22.232 16.875C22.3065
          17.0983 22.5297 17.247 22.7529 17.247H23.9433C24.241 17.247 24.4642 17.0238 24.4642 16.7263V16.6518C24.1665
          15.0149 22.8272 13.75 21.116 13.6013V11.8156C21.116 11.5179 20.8928 11.2947 20.5208 11.2202H19.4047C19.107
          11.2202 18.8838 11.4434 18.8094 11.8156V13.5268C16.5772 13.8245 15.1636 15.3125 15.1636 17.1727C15.1636
          19.6281 16.6517 20.5952 19.7767 20.9674C21.8601 21.3393 22.5297 21.7858 22.5297 22.9763C22.5297 24.1668
          21.4879 24.9852 20.0744 24.9852C18.1397 24.9852 17.4701 24.1666 17.2469 23.0506C17.1726 22.7531 16.9494
          22.6041 16.7261 22.6041H15.4611C15.1636 22.6041 14.9404 22.8274 14.9404 23.125V23.1995C15.2379 25.0595
          16.4285 26.3988 18.8838 26.7709V28.5566C18.8838 28.8541 19.107 29.0774 19.479 29.1518H20.5951C20.8928 29.1518
          21.116 28.9286 21.1904 28.5566V26.7709C23.4226 26.3988 24.9106 24.8363 24.9106 22.8274Z"
          fill="white"
        />
        <path
          d="M16.2053 30.6401C10.4018 28.5569 7.42552 22.0837 9.58338 16.3544C10.6995 13.2294 13.1548 10.8485 16.2053
          9.73246C16.503 9.58371 16.6518 9.36049 16.6518 8.98835V7.94674C16.6518 7.64906 16.503 7.42585 16.2053
          7.35156C16.1309 7.35156 15.9821 7.35156 15.9077 7.42585C8.83927 9.65799 4.97016 17.173 7.20231
          24.2414C8.54159 28.408 11.7411 31.6075 15.9077 32.9467C16.2053 33.0955 16.503 32.9467 16.5773 32.6491C16.6518
          32.5748 16.6518 32.5003 16.6518 32.3516V31.3098C16.6518 31.0866 16.4286 30.7891 16.2053 30.6401ZM24.0923
          7.42585C23.7946 7.2771 23.497 7.42585 23.4227 7.72353C23.3482 7.79799 23.3482 7.87228 23.3482
          8.02121V9.06281C23.3482 9.36049 23.5714 9.65799 23.7946 9.80692C29.5982 11.8901 32.5745 18.3633 30.4166
          24.0926C29.3005 27.2176 26.8452 29.5985 23.7946 30.7146C23.497 30.8633 23.3482 31.0866 23.3482
          31.4587V32.5003C23.3482 32.798 23.497 33.0212 23.7946 33.0955C23.8691 33.0955 24.0178 33.0955 24.0923
          33.0212C31.1607 30.7891 35.0298 23.2741 32.7977 16.2057C31.4584 11.9646 28.1845 8.76513 24.0923 7.42585Z"
          fill="white"
        />
      </svg>
    </Icon>
  )
}

export default USDCIcon
