import { useEffect, useState } from 'react'
import classNames from 'classnames'

import { ArrowIcon } from '../Icons'
import { FooterMarginBottom } from '../Footer'
import './index.scss'

interface IUpButton {
  fixed: boolean,
}

const UpButton = ({ fixed }: IUpButton) => {
  const paddingBottom = 24
  const [rightPosition, setRightPosition] = useState(0)
  const [bottomPosition, setBottomPosition] = useState(paddingBottom)

  const buttonClickHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const getRightPosition = () => {
    const padding = window.innerWidth <= 744 ? 16 : 24

    const footer = document.querySelector('.footer') as HTMLDivElement
    const root = document.getElementById('root') as HTMLDivElement
    const narrowScreen = window.innerWidth <= footer.clientWidth + padding * 2


    if (narrowScreen) {
      setRightPosition(padding)
    } else {
      if (!fixed) {
        setRightPosition(footer.offsetLeft)
      } else {
        setRightPosition(root.offsetLeft + footer.offsetLeft)
      }
    }

    if (!fixed) {
      setBottomPosition(footer.clientHeight + FooterMarginBottom + padding)
    } else {
      setBottomPosition(padding)
    }
  }

  useEffect(() => {
    getRightPosition()

    window.addEventListener('resize', getRightPosition)
    return () => window.removeEventListener('resize', getRightPosition)
  }, [fixed])

  return (
    <div 
      className={classNames(
        'up-button',
        !fixed && 'absolute',
      )}
      style={{
        right: `${rightPosition}px`,
        bottom: `${bottomPosition}px`,
      }}
      onClick={buttonClickHandler}
    >
      Up
      <ArrowIcon />
    </div>
  )
}

export default UpButton
