import Icon, { TIconProps } from '../Icon'

const MobileBlueArrowRangeIcon = (props: TIconProps) => {
  return (
    <Icon iconName="blue-arrow-icon" { ...props }>
      <svg
        width="17"
        height="120"
        viewBox="0 0 17 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 120L16.8868 115L11.1132 115L14 120ZM14 0L11.1132 5L16.8867 5L14 0ZM14.5 115.5L14.5 4.5L13.5 4.5L13.5
          115.5L14.5 115.5Z"
          fill="#3B9CF6"
        />
        <circle
          cx="1"
          cy="1"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="5"
          cy="1"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="9"
          cy="1"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="1"
          cy="119"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="5"
          cy="119"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="9"
          cy="119"
          r="1"
          fill="#3B9CF6"
        />
      </svg>
    </Icon>
  )
}

export default MobileBlueArrowRangeIcon
