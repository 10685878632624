import { Logo, NavActions, Socials } from '../index'
import './index.scss'
import useMobile from '../../hooks/useMobile'

export const FooterMarginBottom = 30

const Footer = () => {
  const { mobile } = useMobile()
  
  return (
    <div className="footer">
      <div className="left-section">
        <Logo />
        {!mobile && <Socials/>}
      </div>
      <div className="right-section">
        <NavActions />
        {mobile && <Socials/>}
      </div>
    </div>
  )
}

export default Footer
