import Icon, { TIconProps } from '../Icon'

const GreyVaultIcon = (props: TIconProps) => {
  return (
    <Icon iconName="grey-vault-icon" { ...props }>
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51 12H21C12 12 6 16.5 6 27V37.68C6 38.79 7.14 39.48 8.13 39.03C11.07 37.68 14.46 37.17 18.03 37.8C25.92
          39.21 31.71 46.53 31.5 54.54C31.47 55.8 31.29 57.03 30.96 58.23C30.72 59.16 31.47 60.03 32.43 60.03H51C60
          60.03 66 55.53 66 45.03V27C66 16.5 60 12 51 12ZM36 43.5C31.86 43.5 28.5 40.14 28.5 36C28.5 31.86 31.86 28.5
          36 28.5C40.14 28.5 43.5 31.86 43.5 36C43.5 40.14 40.14 43.5 36 43.5ZM57.75 42C57.75 43.23 56.73 44.25 55.5
          44.25C54.27 44.25 53.25 43.23 53.25 42V30C53.25 28.77 54.27 27.75 55.5 27.75C56.73 27.75 57.75 28.77 57.75
          30V42Z"
          fill="#4B65AE"
        />
        <path
          d="M15 42C8.37 42 3 47.37 3 54C3 56.25 3.63001 58.38 4.74001 60.18C6.81001 63.66 10.62 66 15 66C19.38 66
          23.19 63.66 25.26 60.18C26.37 58.38 27 56.25 27 54C27 47.37 21.63 42 15 42ZM20.91 53.0099L14.52 58.92C14.1
          59.31 13.53 59.5201 12.99 59.5201C12.42 59.5201 11.85 59.31 11.4 58.86L8.43 55.89C7.56 55.02 7.56 53.58 8.43
          52.71C9.3 51.84 10.74 51.84 11.61 52.71L13.05 54.15L17.85 49.71C18.75 48.87 20.19 48.93 21.03 49.83C21.87
          50.73 21.81 52.1699 20.91 53.0099Z"
          fill="#4B65AE"
        />
      </svg>
    </Icon>
  )
}

export default GreyVaultIcon
