import Icon, { TIconProps } from '../Icon'

const USDVIcon = (props: TIconProps) => {
  return (
    <Icon iconName="usdv-icon" { ...props }>
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.0001 70.0738C54.8185 70.0738 70.0738 54.8185 70.0738 36.0001C70.0738 17.1818 54.8185 1.92651 36.0001
          1.92651C17.1818 1.92651 1.92651 17.1818 1.92651 36.0001C1.92651 54.8185 17.1818 70.0738 36.0001 70.0738Z"
          fill="#5AA0F2"
        />
        <path
          d="M35.9667 54.0205L33.8834 52.0202C27.0581 45.4675 22.9445 36.1819 21.9851 25.1686L29.7503 23.9385C30.476
          32.2666 31.4513 40.1025 35.9684 45.4568C40.4856 40.1025 41.4609 32.2683 42.1866 23.9385L49.9518
          25.1686C48.9924 36.1836 44.8771 45.4693 38.0517 52.0221L35.9667 54.0205Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.6704 44.9642H56.0883L57.9806 40.2546H49.8633C49.2179 41.8829
          48.4865 43.4547 47.6704 44.9642ZM51.0498 36.8908H58.9898L60.84 32.1812H52.266C51.9309 33.7909 51.5253 35.3621
          51.0498 36.8908ZM21.4021 40.2546C22.0475 41.8829 22.7788 43.4546 23.595 44.9642L15.7197 44.9642L13.8274
          40.2546L21.4021 40.2546ZM18.9996 32.1812C19.3346 33.7909 19.7402 35.3621 20.2157 36.8908L12.8182
          36.8908L10.968 32.1812L18.9996 32.1812Z"
          fill="white"
        />
      </svg>
    </Icon>
  )
}

export default USDVIcon
