import {
  BlueArrowRangeIcon,
  MobileBlueArrowRangeIcon,
  MobileRedArrowRangeIcon,
  RedArrowRangeIcon,
} from '../../../../../../components/Icons'
import useMobile from '../../../../../../hooks/useMobile'

const StageTwo = () => {
  const { windowWidth } = useMobile()
  
  return (
    <div className="stages second example-block">
      <div className="top-section">
        <div className="description">
          <div>Stage 2</div>
          <span>
            The BTC/USD exchange rate fell.
            {' '}
            As a result, the collateral worth dropped to $400 per 0.040 BTC<sup>1</sup>.
          </span>
          <span>
            The collateralization ratio dropped to 133.3%<sup>3</sup>,
            {' '}
            which is lower than the minimum collateralization ratio of 150%.
          </span>
          <span>The vault has to be liquidated.</span>
        </div>
        <div className="value-legend">
          <div className="item">
            <div className="color-indicator collateral" />
            <div className="explanation">Collateral value</div>
          </div>
          <div className="item">
            <div className="color-indicator loan" />
            <div className="explanation">Loan value</div>
          </div>
        </div>
      </div>
      <div className="visual-representation">
        <div className="collateral">
          <div className="value-notice">
            {windowWidth > 837 || windowWidth < 670 ? (
              <span>
                Liquidation value at $450<sup>2</sup>
              </span>
            ) : (
              <span>
                Liquidation value <br />
                at $450<sup>2</sup>
              </span>
            )}
          </div>
          <div className="value-notice">
            <span>
              0.040 BTC or $400<sup>1</sup>
            </span>
          </div>
          {windowWidth > 669 ? <RedArrowRangeIcon /> : <MobileRedArrowRangeIcon />}
          {windowWidth > 669 ? <BlueArrowRangeIcon /> : <MobileBlueArrowRangeIcon />}
          <div className="value">
            0.040 BTC {windowWidth <= 669 && <br />} or $900
          </div>
          <div className="column">
            <div className="blue-layer" />
            <div className="rate">
              <span>133.33%</span>
              <span>of the Loan<sup>2</sup></span>
            </div>
          </div>
          <div className="name">
            Collateral
          </div>
        </div>
        <div className="loan">
          <div className="value">
            300 USDV {windowWidth <= 669 && <br />} or $300
          </div>
          <div className="column" />
          <div className="name">
            Loan
          </div>
        </div>
      </div>
      <div className="footnote">
        <div className="note">
          <span>1) BTC/USD = $10,000</span>
          {windowWidth <= 669 ? null : <span className="divider"/>}
          <span>
            400 = 0.040 BTC x $10,000
          </span>
        </div>
        <div className="note">
          <span>2) Liquidation value = $300 x 150% =  $450</span>
        </div>
        <div className="note">
          <span>3) Collateralization ratio = ($400 / $300) x 100% = 133.3%</span>
        </div>
      </div>
    </div>
  )
}

export default StageTwo
