import Icon, { TIconProps } from '../Icon'

const MobileRedArrowRangeIcon = (props: TIconProps) => {
  return (
    <Icon iconName="red-arrow-icon" { ...props }>
      <svg
        width="17"
        height="135"
        viewBox="0 0 17 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8"
          cy="1"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="12"
          cy="1"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="16"
          cy="1"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="8"
          cy="134"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="12"
          cy="134"
          r="1"
          fill="#FC5353"
        />
        <circle
          cx="16"
          cy="134"
          r="1"
          fill="#FC5353"
        />
        <path
          d="M3 135L5.88675 130L0.113249 130L3 135ZM3.00001 0L0.113254 5L5.88676 5L3.00001 0ZM3.5 130.5L3.50001
          4.5L2.50001 4.5L2.5 130.5L3.5 130.5Z"
          fill="#FC5353"
        />
      </svg>

    </Icon>
  )
}

export default MobileRedArrowRangeIcon
