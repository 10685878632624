import { EnvironmentType, NetworkType } from '../enums'

export const getWETHPoolStrategyAddress = (chain: string) => {
  switch(chain) {
  case NetworkType.fantom:
    switch (process.env.REACT_APP_TYPE) {
    case EnvironmentType.DEV:
      return '0xFD91C8744a00873D4E8752cE264397EFa2ea2Ad3'
    case EnvironmentType.STAGE:
      return ''
    case EnvironmentType.PROD:
      return ''
    default:
      return ''
    }
  case NetworkType.ethereum:
    switch (process.env.REACT_APP_TYPE) {
    case EnvironmentType.DEV:
      return '0xA64ACB50C7A047B66A61954e804C9fD507e1aFba'
    case EnvironmentType.STAGE:
      return '0xb7A12DF9071B2Fed814599F86628f7676796932D'
    case EnvironmentType.PROD:
      return '0xe98405b2dc90A7c6365912A441BE310C9Ce7e3C3'
    default:
      return ''
    }
  default:
    return ''
  }
}
