import { useCallback, useState } from 'react'
import * as echarts from 'echarts'
import { EChartsOption } from 'echarts'
import { formatEther, formatUnits } from 'viem'
import BigNumber from 'bignumber.js'
import { getPublicClient } from '@wagmi/core'

import { ChartData, MulticallReturnData, Pool, PoolConfig } from '../types'
import { EnvironmentType, NetworkType } from '../../../enums'
import { getChainID } from '../../../utils/getChainID'
import allPools from '../config/allPools'

export interface IStatisticsContext {
  myChart?: echarts.ECharts,
  pools: Pool[],
  usdvMinted: BigNumber | null,
  getChartData: () => ChartData[],
  getCurrentCollateralRatio: () => number,
  getLockedInVaults: () => number,
  getLockedTokenValues: () => number[],
  initMyChart: (chartData: ChartData[]) => void,
  initPools: () => Promise<void>,
  setMyChart: (value?: echarts.ECharts) => void,
}

export const useStatistics = () => {
  const [pools, setPools] = useState<Pool[]>([])
  const [usdvMinted, setUsdvMinted] = useState<BigNumber | null>(null)
  const [myChart, setMyChart] = useState<echarts.ECharts | undefined>()

  const getMulticallConfig = (config: PoolConfig) => {
    const poolContract = {
      address: config.contractAddress as `0x${string}`,
      abi: config.abi,
    }

    const stablecoinContract = {
      address: config.stablecoinAddress as `0x${string}`,
      abi: config.stablecoinAbi,
    }

    const erc20Contract = {
      address: config.erc20Address as `0x${string}`,
      abi: config.erc20Abi,
    }

    return config.erc20Address ? [
      {
        ...poolContract,
        functionName: 'name',
      },
      {
        ...poolContract,
        functionName: 'totalCollateral',
        chainId: getChainID(config.network),
      },
      {
        ...stablecoinContract,
        functionName: 'decimals',
        chainId: getChainID(config.network),
      },
      {
        ...poolContract,
        functionName: 'getEthPriceSource',
        chainId: getChainID(config.network),
      },
      {
        ...poolContract,
        functionName: 'supply',
        chainId: getChainID(config.network),
      },
      {
        ...erc20Contract,
        functionName: 'decimals',
        chainId: getChainID(config.network),
      },
    ] : [
      {
        ...poolContract,
        functionName: 'name',
      },
      {
        ...poolContract,
        functionName: 'totalCollateral',
        chainId: getChainID(config.network),
      },
      {
        ...stablecoinContract,
        functionName: 'decimals',
        chainId: getChainID(config.network),
      },
      {
        ...poolContract,
        functionName: 'getEthPriceSource',
        chainId: getChainID(config.network),
      },
      {
        ...poolContract,
        functionName: 'supply',
        chainId: getChainID(config.network),
      },
    ]
  }

  const getPoolData = (data: MulticallReturnData, config: PoolConfig): Pool => {
    const network = config.network
    const originalName = data[0].result
    const contractAddress = config.contractAddress
    const lockedInVaultsBN = data[1].result as bigint
    const stablecoinDecimals = data[2].result as number
    const ethPriceSourceBN = data[3].result as bigint
    const usdvMintedBN = data[4].result as bigint
    let erc20Decimals = 0
    if (data[5]) {
      erc20Decimals = data[5].result as number
    }

    const collateral = config.collateral
    const decimals = config.erc20Address ? erc20Decimals : stablecoinDecimals
    const ethPriceSource = new BigNumber(formatUnits(ethPriceSourceBN, 8))
    const lockedInVaults = new BigNumber(formatUnits(lockedInVaultsBN, decimals)).times(ethPriceSource)
    const usdvMinted = new BigNumber(formatEther(usdvMintedBN))
    const strategy = config.strategy
    const strategyType = config.strategyType

    return {
      network,
      originalName,
      contractAddress,
      collateral,
      decimals,
      lockedInVaults,
      usdvMinted,
      ethPriceSource,
      strategy,
      strategyType,
    }
  }
  
  const getUSDVMinted = (pools: Pool[]) => {
    if (process.env.REACT_APP_TYPE === EnvironmentType.DEV) {
      const filteredVaults = pools.filter((pool, index) => {
        if (index === 0) {
          return true
        } else {
          return pool.network !== pools[index - 1].network
        }
      })

      let result = new BigNumber('0')
      for (const vault of filteredVaults) {
        result = result.plus(vault.usdvMinted)
      }

      setUsdvMinted(result)
    } else {
      let totalMinted = new BigNumber(0)

      for (const pool of pools) {
        totalMinted = totalMinted.plus(pool.usdvMinted)
      }

      setUsdvMinted(totalMinted)
    }
  }

  const getPool = async (config: PoolConfig): Promise<Pool> => {
    const publicClient = getPublicClient({
      chainId: getChainID(config.network),
    })

    const data = await publicClient.multicall({
      contracts: getMulticallConfig(config),
    }) as unknown as MulticallReturnData

    return getPoolData(data, config)
  }

  const initPools = async (): Promise<void> => {
    const chains = [NetworkType.fantom, NetworkType.ethereum, NetworkType.bsc]
    let pools: Pool[] = []

    for (const chain of chains) {
      const poolsConfigData = allPools[chain]


      for (const config of poolsConfigData) {
        const newPool = await getPool(config)
        pools.push(newPool)
      }
    }

    setPools(pools)
    getUSDVMinted(pools)
  }

  const initMyChart = (chartData: ChartData[]) => {
    const option: EChartsOption = {
      color: [
        '#5B74EF',
        '#F7931A',
        '#E1D249',
        '#7858E3',
        '#3ED39E',
        '#D75DE1',
        '#3E5BE8',
        '#D2595A',
        '#13B5EC',
        '#582CEB',
        '#208F75',
      ],
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          name: 'Locked in vaults',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#1C2B54',
            borderWidth: 6,
          },
          label: {
            show: false,
            position: 'center',
            color: '#FFFFFF',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 24,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          center: ['45%', '50%'],
          data: chartData,
        },
      ],
    }

    myChart?.setOption(option)
  }

  const getLockedInVaults = useCallback((): number => {
    let lockedInVaults = 0
    pools.forEach(pool => lockedInVaults += pool.lockedInVaults.toNumber())
    return lockedInVaults
  }, [pools])

  const getCurrentCollateralRatio = useCallback((): number => {
    return getLockedInVaults() > 0
      ? getLockedInVaults() / (usdvMinted?.toNumber() as number) * 100
      : 0
  }, [getLockedInVaults, usdvMinted])

  const getLockedTokenValues = useCallback((): number[] => {
    return pools.map(
      (pool) =>
        (pool.lockedInVaults.toNumber() / getLockedInVaults()) * 100,
    )
  }, [pools, getLockedInVaults])

  const getValidValue = (value: string): number => {
    const result = Number(value)
    return result > 0 && result !== Infinity ? result : 0
  }

  const getChartData = useCallback((): ChartData[] => {
    const chartData: ChartData[] = []

    const labels: string[] = []
    const values: number[] = []

    pools?.forEach(pool => {
      let result = ''

      if (pool.strategy) {
        result = pool.originalName
          .replace('xUSDV', '')
          .replace('xSTAR', '')
          .replace('xPEAR', '')
      } else {
        result = pool.collateral.toUpperCase()
      }

      if (!labels.includes(result)) {
        labels.push(result)
        values.push(getValidValue(pool.lockedInVaults.toFixed(2) as string))
      } else {
        values[labels.indexOf(result)] += getValidValue(pool.lockedInVaults.toFixed(2) as string)
      }
    })

    labels.forEach(
      (label, index) => {
        chartData.push({
          value: values[index],
          name: label,
        })
      },
    )
    return chartData
  }, [pools])

  return {
    myChart,
    pools,
    usdvMinted,
    getChartData,
    getCurrentCollateralRatio,
    getLockedInVaults,
    getLockedTokenValues,
    initMyChart,
    initPools,
    setMyChart,
  }
}
