export const PLATFORMS = [
  {
    name: 'Yearn Finance',
    icon: 'yearn-finance',
  },
  {
    name: 'Curve',
    icon: 'curve-finance',
  },
  {
    name: '1inch',
    icon: 'one-inch',
  },
  {
    name: 'SpookySwap',
    icon: 'spooky',
  },
  {
    name: 'Paraswap',
    icon: 'paraswap',
  },
  {
    name: '0x',
    icon: 'zero-x',
  },
  {
    name: 'Beefy',
    icon: 'beefy',
  },
]
