import Icon, { TIconProps } from '../Icon'

const GreyUSDVIcon = (props: TIconProps) => {
  return (
    <Icon iconName="grey-usdv-icon" { ...props }>
      <svg
        width="73"
        height="72"
        viewBox="0 0 73 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.9175 9.60059C41.9308 9.60059 41.5537 10.8974 42.3925 11.4171C46.0844 13.7046 49.1713 16.8801 51.3523
          20.6441C51.527 20.9458 51.8511 21.1296 52.2 21.1296H61.626C64.1108 21.1296 66.1252 19.1152 66.1252
          16.6305V14.0997C66.1252 11.6149 64.1108 9.60059 61.626 9.60059H42.9175ZM54.1843 24.5039C53.5028 24.5039
          53.0221 25.1819 53.2583 25.8212C54.0799 28.0453 54.6092 30.41 54.7988 32.8681C54.8386 33.3834 55.2633
          33.7834 55.78 33.7834H68.6559C70.5195 33.7834 72.0303 32.2726 72.0303 30.409V27.8783C72.0303 26.0146
          70.5195 24.5039 68.6559 24.5039H54.1843ZM55.7802 37.1574C55.2633 37.1574 54.8386 37.5581 54.7988
          38.0735C54.6134 40.4772 54.103 42.791 53.3121 44.9721C53.0786 45.6161 53.5499 46.2965 54.2348
          46.2965H62.7508C64.6144 46.2965 66.1252 44.7857 66.1252 42.9219V40.5318C66.1252 38.6681 64.6144 37.1574
          62.7508 37.1574H55.7802ZM52.2812 49.6709C51.9303 49.6709 51.6047 49.8572 51.4305 50.1619C49.2903 53.9063
          46.257 57.076 42.6231 59.3798C41.787 59.9099 42.1526 61.1999 43.1427 61.1999H64.1568C66.6416 61.1999 68.6559
          59.1855 68.6559 56.7008V54.17C68.6559 51.6852 66.6416 49.6709 64.1568 49.6709H52.2812Z"
          fill="#4B65AE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.4767 21.3398C18.7236 21.3398 12.417 27.6465 12.417 35.3996C12.417 43.1527 18.7236 49.4594 26.4767
          49.4594C34.2299 49.4594 40.5365 43.1527 40.5365 35.3996C40.5365 27.6465 34.2299 21.3398 26.4767
          21.3398ZM25.6835 42.6928L26.6114 43.5777L27.5401 42.6936C30.5801 39.7947 32.413 35.6868 32.8404
          30.8139L29.3818 30.2697C29.0585 33.9547 28.6241 37.4205 26.6122 39.7892C24.6003 37.4205 24.1658 33.954
          23.8426 30.2697L20.384 30.8139C20.8113 35.686 22.6435 39.7939 25.6835 42.6928ZM35.5738
          39.5714H31.8247C32.1883 38.9036 32.514 38.2083 32.8015 37.4879H36.4166L35.5738 39.5714ZM33.3299
          35.9998H36.8661L37.6901 33.9163H33.8716C33.7224 34.6285 33.5417 35.3236 33.3299 35.9998ZM20.1249
          37.4879C20.4124 38.2082 20.7381 38.9036 21.1016 39.5714H17.594L16.7512 37.4879H20.1249ZM19.0548
          33.9163C19.204 34.6285 19.3847 35.3236 19.5965 35.9998H16.3017L15.4776 33.9163H19.0548Z"
          fill="#4B65AE"
        />
        <path
          d="M26.477 10.0928C12.5223 10.0928 1.16943 21.4457 1.16943 35.4003C1.16943 49.355 12.5223 60.7079 26.477
          60.7079C40.4317 60.7079 51.7845 49.355 51.7845 35.4003C51.7845 21.4457 40.4317 10.0928 26.477
          10.0928ZM26.477 17.6406C36.27 17.6406 44.2367 25.6073 44.2367 35.4003C44.2367 45.1933 36.27 53.16 26.477
          53.16C16.684 53.16 8.7173 45.1933 8.7173 35.4003C8.7173 25.6073 16.684 17.6406 26.477 17.6406Z"
          fill="#4B65AE"
        />
      </svg>
    </Icon>
  )
}

export default GreyUSDVIcon
