import useMobile from '../../../../../../hooks/useMobile'

const StageFour = () => {
  const { windowWidth } = useMobile()

  return (
    <div className="stages fourth example-block">
      <div className="top-section">
        <div className="description">
          <div>Stage 4</div>
          <span>The liquidation process is now finished.</span>
          <span>50% of the debt was liquidated (150 USDV remained).</span>
          <span>The remaining collateral is 0.02335 BTC ($233)<sup>1</sup>.</span>
          <span>The collateral ratio went up to 155.6%<sup>2</sup>.</span>
        </div>
        <div className="value-legend">
          <div className="item">
            <div className="color-indicator collateral" />
            <div className="explanation">Collateral value</div>
          </div>
          <div className="item">
            <div className="color-indicator loan" />
            <div className="explanation">Loan value</div>
          </div>
        </div>
      </div>
      <div className="visual-representation">
        <div className="collateral">
          <div className="value">
            0.02335 BTC {windowWidth <= 669 && <br />} or $233
          </div>
          <div className="column">
            <div className="rate">
              <span>155.6%</span>
              <span>of the Loan<sup>2</sup></span>
            </div>
          </div>
          <div className="name">
            Collateral
          </div>
        </div>
        <div className="loan">
          <div className="value">
            150 USDV {windowWidth <= 669 && <br />} or $150
          </div>
          <div className="column" />
          <div className="name">
            Loan
          </div>
        </div>
      </div>
      <div className="footnote">
        <div className="note">
          <span>1) 0.040BTC - 0.01665 BTC = 0.02335 BTC</span>
        </div>
        <div className="note">
          <span>2) Collateralization ratio = ($233.5 / $150) x 100% = 155.6%</span>
        </div>
      </div>
    </div>
  )
}

export default StageFour
