import { EnvironmentType } from '../enums'

export const getVenusBNBPoolAddress = () => {
  switch (process.env.REACT_APP_TYPE) {
  case EnvironmentType.DEV:
    return '0x6857A6dAe18A6db1536FFE138f3eC32C62a5E13E'
  case EnvironmentType.STAGE:
    return '0xC9dd477CA802a05312c6e705eEBcB26A2edb4f70'
  case EnvironmentType.PROD:
    return '0x2fED640e4E7178d5Cc27Ee981468F8D40d471823'
  default:
    return ''
  }
}
