import { ExampleNotification } from '../../../../components'
import useMobile from '../../../../hooks/useMobile'
import './index.scss'

const CollateralizationBlock = () => {
  const { windowWidth } = useMobile()

  return (
    <div className="collateralization-block">
      <div className="block-title">
        Collateralization principal
      </div>
      <div className="grey-20-text">
        The main principle of the USDV Protocol is that each issued stablecoin must be overcollateralized by
        another crypto asset to provide a reliable and secure instrument for users. To grant all participants of
        the project a proven business model, users can collateralize the most liquid assets in the market, such as:
        BTC, ETH, and FTM. Each asset has a pre-defined collateral ratio depending on its volatility and own risks.
      </div>
      <ExampleNotification>
        <div className="text">
          A User provides $900 worth of BTC as a collateral with a minimum collateral ratio of 150%.
          A User can get a loan of not more than 600 USDV, which is too risky.
          To protect their collateral from a quick liquidation in the volatile market,
          a user can mint 300 USDV with a collateral ratio of 300%.
        </div>
      </ExampleNotification>
      <div className="example-block">
        <div className="top-section">
          <div className="description">
            <span>User deposits 0.040 BTC<sup>1</sup>, which is worth $900.</span>
            <span>The maximum amount to borrow is $600.</span>
            <span>The collateration ratio can't be less than 150%.</span>
          </div>
          <div className="value-legend">
            <div className="item">
              <div className="color-indicator collateral" />
              <div className="explanation">Collateral value</div>
            </div>
            <div className="item">
              <div className="color-indicator loan" />
              <div className="explanation">Loan value</div>
            </div>
          </div>
        </div>
        <div className="visual-representation">
          <div className="collateral">
            <div className="value">
              0.040 BTC {windowWidth <= 669 && <br />} or $900<sup>1</sup>
            </div>
            <div className="column">
              <div className="rate">
                <span>150%</span>
                <span>of the Loan<sup>2</sup></span>
              </div>
            </div>
            <div className="name">
              Collateral
            </div>
          </div>
          <div className="loan">
            <div className="value">
              600 USDV <br />or $600 max loan
            </div>
            <div className="column" />
            <div className="name">
              Loan
            </div>
          </div>
        </div>
        <div className="footnote">
          <div className="note">
            <span>1) 1 BTC = $22,500</span>
            {windowWidth <= 669 ? null : <span className="divider"/>}
            <span>
              $900 = 0.040 BTC x $22,500
            </span>
          </div>
          <div className="note">
            <span>2) Collateralization ratio = ($900 / $600) x 100% = 150%</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollateralizationBlock
