import { Abi } from 'abitype'

import { PoolConfig, StrategyVaultsType } from '../../types'
import { NetworkType } from '../../../../enums'
import { ERC20 } from '../../abi/ERC20'
import { StrategyVault } from '../../abi/StrategyVault'
import { getWETHPoolStrategyAddress } from '../../../../utils/getWETHPoolStrategyAddress'
import { getUSDVAddress } from '../../../../utils/getUSDVAddress'

export const WETHxUSDV_STRATEGY_YEARN: PoolConfig = {
  collateral: 'wETH',
  contractAddress: getWETHPoolStrategyAddress(NetworkType.fantom),
  abi: StrategyVault as Abi,
  network: NetworkType.fantom,
  decimals: 18,
  erc20Address: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
  erc20Abi: ERC20 as Abi,
  stablecoinAddress: getUSDVAddress(NetworkType.fantom),
  stablecoinAbi: ERC20 as Abi,
  strategy: true,
  strategyType: StrategyVaultsType.Yearn,
}
