import './index.scss'
import {
  BitcoinIcon,
  BNBIcon,
  BOOIcon,
  FTMIcon,
  HAIIcon,
  WETHIcon,
} from '../../../../components/Icons'
import useMobile from '../../../../hooks/useMobile'

const ZeroInterestBlock = () => {
  const { windowWidth } = useMobile()

  return (
    <div className="zero-interest-block">
      <div className="left-section">
        <div className="title">0% interest</div>
        <div className="description">
          We created a way for you to keep your crypto and still be able to spend its value.
          You are able to borrow stablecoins without having to sell your crypto assets, and do so at 0% interest!
        </div>
        <div className="icons">
          <FTMIcon />
          <HAIIcon />
          <BNBIcon />
          <WETHIcon />
          <BOOIcon />
          <BitcoinIcon />
        </div>
      </div>
      {windowWidth > 699 && (
        <div className="right-section">
          <div className="zero-interest-image" />
        </div>
      )}
    </div>
  )
}

export default ZeroInterestBlock
