import { CreateVaultBtn } from '../../../../components'
import useMobile from '../../../../hooks/useMobile'

import {BlockIntroduction, GenerationSteps, GenerationStepsMobile} from './components'

import './index.scss'

const GenerateBlock = () => {
  const { windowWidth } = useMobile()
  
  return (
    <div className="generate-block">
      {windowWidth >= 892 && (
        <img
          src={require('../../../../assets/images/usdv-banner.png')}
          alt="usdv"
          className="usdv-banner"
        />
      )}
      <div className="page-title">
        How it works
      </div>
      <BlockIntroduction />
      {windowWidth > 599 ? <GenerationSteps /> : <GenerationStepsMobile />}
      <CreateVaultBtn />
    </div>
  )
}

export default GenerateBlock
