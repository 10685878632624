import { useState } from 'react'

import useMobile from '../../hooks/useMobile'
import { BurgerMenu,
  BurgerMenuBtn,
  Logo,
  NavActions,
} from '../index'
import './index.scss'

const Header = () => {
  const { windowWidth } = useMobile()
  const [burgerOpen, setBurgerOpen] = useState(false)
  const [burgerVisible, setBurgerVisible] = useState(false)

  const burgerOpenHandler = () => {
    if (!burgerVisible) {
      setBurgerOpen(true)
      setBurgerVisible(true)
    } else {
      setBurgerVisible(false)
      setTimeout(() => setBurgerOpen(false), 380)
    }
  }

  
  return (
    <>
      <div className="header">
        <div className="content">
          <div
            className="left-section"
            onClick={burgerOpenHandler}
          >
            <Logo />
          </div>
          <div className="right-section">
            {windowWidth <= 744
              ? (
                <BurgerMenuBtn
                  onClick={burgerOpenHandler}
                  checked={burgerVisible}
                />
              )
              : <NavActions />}
          </div>
        </div>
      </div>
      {burgerOpen && windowWidth <= 744 && (
        <BurgerMenu
          burgerVisible={burgerVisible}
          onClose={burgerOpenHandler}
        />
      )}
    </>
  )
}

export default Header
