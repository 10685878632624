import useMobile from '../../../../../../hooks/useMobile'
import { MobileRedArrowRangeIcon, RedArrowRangeIcon } from '../../../../../../components/Icons'
import './index.scss'

const StageOne = () => {
  const { windowWidth } = useMobile()

  return (
    <div className="stages example-block">
      <div className="top-section">
        <div className="description">
          <div>Stage 1</div>
          <span>
            User deposited 0.040 BTC ($900)<sup>1</sup> and borrowed 300 USDV. {windowWidth > 837 && <br />}
            In such a case, the collateralization ratio is 300%.
          </span>
          <span>
            The liquidation happens when the price of BTC/USD goes down and the collateral ratio drops below 150%.
          </span>
          <span>In this example, the liquidation value is $450<sup>3</sup>.</span>
        </div>
        <div className="value-legend">
          <div className="item">
            <div className="color-indicator collateral" />
            <div className="explanation">Collateral value</div>
          </div>
          <div className="item">
            <div className="color-indicator loan" />
            <div className="explanation">Loan value</div>
          </div>
        </div>
      </div>
      <div className="visual-representation">
        <div className="collateral">
          <div className="value-notice">
            {windowWidth > 837 || windowWidth < 670 ? (
              <span>
                Liquidation value at $450 <br />
                (150% collateral ratio)<sup>3</sup>
              </span>
            ) : (
              <span>
                Liquidation value <br />
                at $450 (150% <br />
                collateral ratio)<sup>3</sup>
              </span>
            )}
          </div>
          {windowWidth > 669 ? <RedArrowRangeIcon /> : <MobileRedArrowRangeIcon />}
          <div className="value">
            0.040 BTC {windowWidth <= 669 && <br />} or $900<sup>1</sup>
          </div>
          <div className="column">
            <div className="rate">
              <span>300%</span>
              <span>of the Loan<sup>2</sup></span>
            </div>
          </div>
          <div className="name">
            Collateral
          </div>
        </div>
        <div className="loan">
          <div className="value">
            300 USDV {windowWidth <= 669 && <br />} or $300
          </div>
          <div className="column" />
          <div className="name">
            Loan
          </div>
        </div>
      </div>
      <div className="footnote">
        <div className="note">
          <span>1) BTC/USD = $22,500</span>
        </div>
        <div className="note">
          <span>2) Collateralization ratio = ($900 / $300) x 100% = 300%</span>
        </div>
        <div className="note">
          <span>3) Liquidation value = $300 x 150% = $450</span>
        </div>
      </div>
    </div>
  )
}

export default StageOne
