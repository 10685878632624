import { EnvironmentType } from '../enums'

export const getFTMPoolStrategyAddress = (): string => {
  switch (process.env.REACT_APP_TYPE) {
  case EnvironmentType.DEV:
    return '0xBcE558bA11631316393200828007a6A7B7529cb8'
  case EnvironmentType.STAGE:
    return '0x966e6bc64f0B7B3A844718b1f0BE4B837c7eb0a5'
  case EnvironmentType.PROD:
    return '0xBdaa6f73c7d96F800dAE9B39c84cA82396a2CEf6'
  default:
    return ''
  }
}
