import Icon, { TIconProps } from '../Icon'

const WETHIcon = (props: TIconProps) => {
  return (
    <Icon iconName="weth-icon" { ...props }>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_740_55761)">
          <path
            d="M11.2134 39.6721C21.2675 39.6721 21.2675 39.5224 22.9723 39.0164C25.2001 38.355 16.6823 32.459 11.2134
            32.459C5.74463 32.459 1.31128 34.0737 1.31128 36.0655C1.31128 38.0574 5.74485 39.6721 11.2134 39.6721Z"
            fill="black"
          />
          <path
            d="M34.0434 9.00274L34.5058 9.50109L34.1015 10.0477C20.9892 27.7766 13.4356 35.8038 10.2307 34.7272C8.27857
            34.0714 6.32088 32.0992 4.36315 29.2355C3.67501 28.2289 3.02883 27.1689 2.44112 26.1141C2.04976 25.4117
            1.71852 24.7711 1.54653 24.4103C1.32713 23.95 1.2345 23.6664 1.16102 23.294C1.15653 23.2712 1.15653 23.2712
            1.15227 23.2493C1.1216 23.0912 1.11099 23.0412 1.08806 22.9548C0.693501 21.4678 0.491699 19.9271 0.491699
            18.3606C0.491699 8.49186 8.49186 0.491699 18.3605 0.491699C21.4523 0.491699 24.4317 1.27856 27.0721
            2.75562L27.1403 2.79828C29.3022 4.30338 31.6016 6.3715 34.0434 9.00274ZM26.2368 4.1668C23.8481 2.83827
            21.1566 2.13104 18.3605 2.13104C9.39724 2.13104 2.13104 9.39724 2.13104 18.3606C2.13104 19.7849 2.31434
            21.1842 2.67258 22.5344C2.708 22.6679 2.72402 22.7433 2.7616 22.937C2.76555 22.9573 2.76555 22.9573
            2.76934 22.9766C2.81677 23.217 2.86681 23.3702 3.02636 23.7049C3.18325 24.034 3.49856 24.6438 3.87319
            25.3162C4.43804 26.33 5.05877 27.3482 5.71648 28.3103C7.48526 30.8977 9.23944 32.6648 10.7527
            33.1732C12.771 33.8512 20.3691 25.8081 32.3768 9.62198C30.1968 7.32093 28.1489 5.50252 26.2368 4.1668Z"
            fill="black"
          />
          <path
            d="M26.2369 4.16662C23.8481 2.83808 21.1566 2.13086 18.3606 2.13086C9.3973 2.13086 2.1311 9.39706 2.1311
            18.3604C2.1311 19.7847 2.3144 21.184 2.67264 22.5342C2.70806 22.6677 2.72408 22.7432 2.76166
            22.9369C2.76561 22.9572 2.76561 22.9572 2.7694 22.9765C2.81683 23.2168 2.86687 23.37 3.02642
            23.7047C3.18332 24.0339 3.49862 24.6436 3.87325 25.316C4.4381 26.3298 5.05884 27.348 5.71654
            28.3101C7.48532 30.8975 9.2395 32.6646 10.7528 33.173C12.7711 33.851 20.3692 25.8079 32.3768
            9.62179C30.1968 7.32075 28.149 5.50233 26.2369 4.16662Z"
            fill="#EC1C79"
          />
          <path
            d="M21.6394 39.5082C11.7707 39.5082 3.77051 31.5081 3.77051 21.6394C3.77051 11.7707 11.7707 3.77051 21.6394
            3.77051C31.5081 3.77051 39.5082 11.7707 39.5082 21.6394C39.5082 31.5081 31.5081 39.5082 21.6394
            39.5082ZM21.6394 37.8689C30.6027 37.8689 37.8689 30.6027 37.8689 21.6394C37.8689 12.6761 30.6027 5.40985
            21.6394 5.40985C12.6761 5.40985 5.40985 12.6761 5.40985 21.6394C5.40985 30.6027 12.6761 37.8689 21.6394
            37.8689Z"
            fill="black"
          />
          <path
            d="M21.6392 37.8687C30.6025 37.8687 37.8687 30.6025 37.8687 21.6392C37.8687 12.6759 30.6025 5.40967
            21.6392 5.40967C12.6759 5.40967 5.40967 12.6759 5.40967 21.6392C5.40967 30.6025 12.6759 37.8687 21.6392
            37.8687Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.49903 24.9636L7.35083 18.4915H9.2328L10.3528 22.2095L11.5921 18.4731H13.1253L14.3646 22.2095L15.4846
            18.4915H17.3298L15.1816 24.9636H13.6302L12.3357 21.264L11.0505 24.9636H9.49903ZM18.0367
            24.9177V18.4915H23.2053V20.0063H19.7994V20.9794H22.8839V22.384H19.7994V23.403H23.2512V24.9177H18.0367ZM25.7298
            24.9177V20.0522H23.802V18.4915H29.4387V20.0522H27.5108V24.9177H25.7298ZM30.219
            24.9177V18.4915H32V20.8968H34.2859V18.4915H36.0669V24.9177H34.2859V22.4758H32V24.9177H30.219Z"
            fill="black"
          />
          <path
            d="M1.55651 16.5587C1.10302 16.3319 0.919204 15.7805 1.14595 15.327C1.37269 14.8735 1.92413 14.6897
            2.37762 14.9165L5.00057 16.2279C5.45406 16.4547 5.63787 17.0061 5.41113 17.4596C5.18438 17.9131 4.63294
            18.0969 4.17946 17.8702L1.55651 16.5587Z"
            fill="black"
          />
          <path
            d="M1.25451 20.908C0.794157 20.6956 0.593198 20.1502 0.805656 19.6898C1.01812 19.2295 1.56354 19.0285
            2.02389 19.241L4.64684 20.4515C5.1072 20.6639 5.30816 21.2094 5.0957 21.6697C4.88324 22.1301 4.33782
            22.331 3.87746 22.1186L1.25451 20.908Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_740_55761">
            <rect
              width="40"
              height="40"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  )
}

export default WETHIcon
