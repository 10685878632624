import './index.scss'

const BlockIntroduction = () => {
  return (
    <div className="block-introduction">
      <div className="block-title">
        How to generate USDV
      </div>
      <div className="grey-20-text">
        USDV protocol allows all users to get a loan in USDV stablecoin
        with collateral in various assets, such as BTC, ETH, and FTM.
      </div>
      <div className="grey-20-text">
        USDV can only be minted through locking collateral to back its value.
      </div>
      <div className="grey-20-text">
        Users can deposit collateral to their vaults and mint USDV against it.
        Users will not be charged any interest for minting USDV through vaults.
        Thus, they can hold their collateral in vaults and use minted USDV
        for as long as possible without any interest.
      </div>
    </div>
  )
}

export default BlockIntroduction
