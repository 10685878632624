import React, { ReactNode } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import useMobile from '../../hooks/useMobile'
import './index.scss'

type TooltipProps = {
  children: ReactNode,
  id: string,
  text: string,
  stopPropagation?: boolean,
} & React.HTMLAttributes<HTMLSpanElement>

const Tooltip = ({ 
  children, 
  text, 
  id, 
  className,
  onClick,
  stopPropagation = true,
}: TooltipProps) => {
  const { windowWidth } = useMobile()

  const onClickHandler = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (stopPropagation) {
      event.stopPropagation()
    }

    if (onClick) {
      onClick?.(event)
    }
  }

  return (
    <>
      <span
        className={className}
        data-tooltip-id={id}
        data-tooltip-content={text}
        onClick={onClickHandler}
      >
        {children}
      </span>
      <ReactTooltip
        id={id}
        noArrow={true}
        place="top"
        openOnClick={windowWidth < 600}
      />
    </>
  )
}

export default Tooltip
