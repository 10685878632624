import { Abi } from 'abitype'

import { NetworkType } from '../../../../enums'
import { Stablecoin } from '../../abi/Stablecoin'
import { PoolConfig } from '../../types'
import { ERC20 } from '../../abi/ERC20'
import { getUSDVAddress } from '../../../../utils/getUSDVAddress'
import { getBNBPoolAddress } from '../../../../utils/getBNBPoolAddress'

export const BNBxUSDV: PoolConfig = {
  collateral: 'BNB',
  contractAddress: getBNBPoolAddress(),
  network: NetworkType.bsc,
  abi: Stablecoin as Abi,
  decimals: 18,
  erc20Address: '',
  erc20Abi: [] as Abi,
  stablecoinAddress: getUSDVAddress(NetworkType.bsc),
  stablecoinAbi: ERC20 as Abi,
  strategy: false,
}
