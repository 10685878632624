import { EnvironmentType } from '../enums'

export const getWBTCPoolStrategyAddress = () => {
  switch (process.env.REACT_APP_TYPE) {
  case EnvironmentType.DEV:
    return '0xf763C985940E0a4d7B26943969bC4201F17F9DA3'
  case EnvironmentType.STAGE:
    return '0xE9044364ea91d966a6730673139d5098Ee4495D5'
  case EnvironmentType.PROD:
    return '0xec24FAFa1B64A607E683a1Ea83975ef3317221Aa'
  default:
    return ''
  }
}
