import { useEffect } from 'react'
import * as echarts from 'echarts'
import { SVGRenderer } from 'echarts/renderers'

import { ChartLegend } from '../index'
import { ValueDataLoader } from '../../../../../../components'
import useMobile from '../../../../../../hooks/useMobile'
import { useStatisticsContext } from '../../../../providers/statisticsContext'
import './index.scss'

const ChartData = () => {
  const {
    getChartData,
    getLockedTokenValues,
    initMyChart,
    setMyChart,
    myChart,
  } = useStatisticsContext()

  const { windowWidth } = useMobile()

  const chartData = getChartData()

  useEffect(() => {
    if (!myChart) {
      // @ts-ignore
      echarts.use([SVGRenderer])

      const chartDom = document.getElementById('chart')!

      const chart = echarts.init(chartDom, undefined, { renderer: 'svg' })
      setMyChart(chart)
    }

    if (chartData.length) {
      initMyChart(chartData)
    }
  }, [chartData, myChart])

  return (
    <div className="chart-data">
      {windowWidth < 1180 && (
        <div className="chart-legend-title">
          <span>Collateral by assets</span>
          <span>*Aggregated data for all integrated networks</span>
        </div>
      )}
      {getChartData().length === 0 && (
        <div className="chart-placeholder">
          <ValueDataLoader />
        </div>
      )}
      <div id="chart" />
      <div className="legend">
        {windowWidth >= 1180 && (
          <div className="chart-legend-title">
            <span>Collateral by assets</span>
            <span>*Aggregated data for all integrated networks</span>
          </div>
        )}
        <ChartLegend
          chartData={chartData}
          lockedTokenValues={getLockedTokenValues()}
          chart={myChart}
        />
      </div>
    </div>
  )
}

export default ChartData
