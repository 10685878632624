import { HTMLAttributes } from 'react'

import { PaperclipIcon } from '../Icons'
import './index.scss'

const ExampleNotification = (props: HTMLAttributes<HTMLDivElement> ) => {
  return (
    <div className="example">
      <div className="example-border"/>
      <div className="content">
        <div className="title">
          <PaperclipIcon />
          Example
        </div>
        {props.children}
      </div>
    </div>
  )
}

export default ExampleNotification
