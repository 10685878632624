import './index.scss'

interface BurgerMenuButtonProps {
  onClick: () => void
  checked: boolean
}

const BurgerMenuBtn = ({ onClick, checked }: BurgerMenuButtonProps) => {
  return (
    <div
      className="menu-icon"
    >
      <input
        id="checkbox"
        className="menu-icon__cheeckbox"
        type="checkbox"
        checked={checked}
        onChange={onClick}
      />
      <div>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default BurgerMenuBtn
