import Icon, { TIconProps } from '../Icon'

const GreyUSDCIcon = (props: TIconProps) => {
  return (
    <Icon iconName="grey-usdc-icon" { ...props }>
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M70.5 36C70.5 55.1217 55.1217 70.5 36 70.5C16.8783 70.5 1.5 55.1217 1.5 36C1.5 16.8783 16.8783 1.5 36
          1.5C55.1217 1.5 70.5 16.8783 70.5 36Z"
          fill="#4B65AE"
          stroke="#1C2B54"
          strokeWidth="3"
        />
        <path
          d="M36.2514 35.3871L36.2619 35.3885L36.2724 35.3898C39.3771 35.7593 41.392 36.364 42.6281 37.3058C43.7386
          38.1519 44.3997 39.4138 44.3997 41.7001C44.3997 44.8779 42.0761 47.5167 38.1531 48.1707L36.8998
          48.3797V49.6503V52.9502H35.2497V49.6503V48.3605L33.9745 48.1672C31.7275 47.8267 30.2003 47.0669 29.1802
          46.0912C28.2804 45.2304 27.6862 44.1169 27.383 42.75H29.0454C29.3377 43.9845 29.9177 45.2091 31.078
          46.1225C32.3175 47.0984 34.0059 47.5503 36.1498 47.5503C39.5144 47.5503 42.5998 45.511 42.5998
          42.0003C42.5998 41.266 42.4975 40.536 42.1946 39.8526C41.8835 39.1507 41.4001 38.5867 40.7741
          38.1376C39.6073 37.3006 37.909 36.8478 35.8133 36.4736L35.7704 36.466L35.7271 36.4608C32.6417 36.0934
          30.6617 35.4555 29.4601 34.5177C28.3717 33.6682 27.7498 32.4328 27.7498 30.3003C27.7498 27.4533 29.8825
          24.9592 33.7979 24.437L35.0996 24.2634V22.9502V19.8H36.7497V23.1003V24.4756L38.1198 24.5947C40.6815 24.8173
          42.7245 26.5836 43.3924 28.9501H41.8256C41.3647 27.7111 40.646 26.697 39.5536 26.0111C38.4146 25.2959
          37.0457 25.0502 35.5497 25.0502C33.8503 25.0502 32.3462 25.4295 31.2378 26.3162C30.0818 27.2408 29.5497
          28.5624 29.5497 30.0001C29.5497 31.2846 29.8388 32.6427 31.1184 33.6786C32.2491 34.594 33.9609 35.0817
          36.2514 35.3871Z"
          fill="#1C2B54"
          stroke="#1C2B54"
          strokeWidth="3"
        />
        <path
          d="M27.75 12.2273V13.7998C27.75 13.8583 27.7461 13.8982 27.7423 13.9231C27.7398 13.9247 27.7371 13.9264
          27.7342 13.9282C21.2327 16.3392 15.9873 21.4374 13.5918 28.1331C8.98367 40.3882 15.2805 54.2084 27.6123
          58.7774C27.6148 58.7797 27.6173 58.7822 27.6197 58.7847C27.6642 58.8291 27.7043 58.8775 27.7355
          58.9225C27.7409 58.9303 27.7457 58.9375 27.75 58.9442V60.5197C20.0362 57.8838 14.1325 51.8787 11.6291
          44.0947C7.42667 30.7805 14.5853 16.6497 27.75 12.2273ZM44.25 13.9194V12.2329C51.8331 14.8734 57.8699 20.8853
          60.3697 28.8016C64.5746 42.1168 57.4159 56.2496 44.25 60.6725V59.1C44.25 59.0415 44.2539 59.0015 44.2576
          58.9766C44.2602 58.975 44.2629 58.9733 44.2658 58.9716C50.7673 56.5606 56.0127 51.4623 58.4082
          44.7666C63.0159 32.5126 56.7206 18.694 44.3911 14.1237C44.3534 14.0851 44.3196 14.0426 44.293 14.0012C44.2689
          13.9638 44.256 13.9355 44.25 13.9194Z"
          fill="#1C2B54"
          stroke="#1C2B54"
          strokeWidth="3"
        />
      </svg>
    </Icon>
  )
}

export default GreyUSDCIcon
