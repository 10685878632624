import { fantom, mainnet, bsc } from '@wagmi/core/chains'

import { NetworkType } from '../enums'

export const getChainID = (chain: string): number => {
  switch(chain) {
  case NetworkType.fantom:
    return fantom.id
  case NetworkType.ethereum:
    return mainnet.id
  case NetworkType.bsc:
    return bsc.id
  default:
    return 0
  }
}
