import Icon, { TIconProps } from '../Icon'

const WBTCIcon = (props: TIconProps) => {
  return (
    <Icon iconName="wbtc-icon" { ...props }>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.783 24.4347C35.3344 34.2562 25.3868 40.2334 15.5642 37.7842C5.74551 35.3356 -0.23173 25.3874 2.21806
          15.5665C4.66557 5.74381 14.6131 -0.23401 24.4329 2.21464C34.255 4.66329 40.2317 14.6126 37.783 24.4347Z"
          fill="#F7931A"
        />
        <path
          d="M28.0835 17.3876C28.4485 14.9481 26.5911 13.6367 24.0513 12.7619L24.8752 9.45727L22.8637 8.95597L22.0616
          12.1735C21.5328 12.0417 20.9897 11.9174 20.45 11.7942L21.2578 8.5555L19.2474 8.0542L18.423 11.3576C17.9853
          11.258 17.5556 11.1594 17.1385 11.0557L17.1408 11.0454L14.3668 10.3527L13.8317 12.5012C13.8317 12.5012
          15.3241 12.8432 15.2926 12.8644C16.1073 13.0678 16.2545 13.6069 16.2299 14.0343L15.2914 17.7989C15.3476
          17.8133 15.4204 17.8339 15.5006 17.866C15.4335 17.8494 15.3619 17.831 15.288 17.8133L13.9726 23.087C13.8729
          23.3345 13.6202 23.7057 13.0508 23.5648C13.0708 23.594 11.5887 23.1998 11.5887 23.1998L10.5901
          25.5024L13.2077 26.1549C13.6947 26.277 14.172 26.4047 14.6418 26.5251L13.8093 29.8675L15.8185 30.3688L16.643
          27.0619C17.1918 27.2108 17.7246 27.3483 18.246 27.4778L17.4244 30.7692L19.4359 31.2705L20.2684
          27.9344C23.6984 28.5835 26.2777 28.3217 27.3634 25.2194C28.2382 22.7215 27.3198 21.2806 25.5152
          20.341C26.8294 20.0379 27.8194 19.1734 28.0835 17.3876ZM23.4876 23.8323C22.866 26.3303 18.6602 24.9799
          17.2967 24.6413L18.4012 20.2132C19.7648 20.5535 24.1373 21.2273 23.4876 23.8323ZM24.1098 17.3515C23.5426
          19.6237 20.0421 18.4693 18.9066 18.1862L19.908 14.1701C21.0435 14.4531 24.7005 14.9813 24.1098 17.3515Z"
          fill="white"
        />
      </svg>
    </Icon>
  )
}

export default WBTCIcon
