import { NetworkType } from '../enums'

export const getSecondRPCURL = (chain: string): string => {
  switch(chain) {
  case NetworkType.fantom:
    return 'https://rpcapi.fantom.network/'
  case NetworkType.ethereum:
    return 'https://ethereum.publicnode.com'
  case NetworkType.bsc:
    return 'https://bsc.meowrpc.com'
  default:
    return ''
  }
}
