import { ExampleNotification } from '../../../../components'
import './index.scss'

const PegMaintainedBlock = () => {
  return (
    <div className="peg-maintained-block">
      <div className="block-title">
        How is peg maintained
      </div>
      <div className="grey-20-text">
        When the price of USDV drops below $0.99 or rises above $1.01, users can get USDV in exchange for DAI or USDC
        with a ratio of 1:1 and gain profits thanks to the arbitrage strategy.
      </div>
      <ExampleNotification>
        <div className="text">
          Price above 1.01: the price of USDV is 1.03. A user can buy USDV with 1.01 DAI
          (including a commission fee of 1%), sell USDV on the market at 1.03 price and gain ~2%.
        </div>
        <div className="text">
          Price below 0.99: the price of USDV is 0.97. 
          A user can buy USDV on the market for 0.97 DAI, swap it for 0.99 DAI and gain ~2%.
        </div>
      </ExampleNotification>
    </div>
  )
}

export default PegMaintainedBlock
