import { HTMLAttributes } from 'react'

import { getLink } from '../../utils/getLink'

const CreateVaultBtn = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <a
      className="btn-primary"
      href={`${getLink()}vaults`}
      target="_blank"
      rel="noreferrer"
      onClick={props.onClick as () => void}
    >
      Create vault
    </a>
  )
}

export default CreateVaultBtn
