import { EnvironmentType } from '../enums'

export const getHAIPoolAddress = () => {
  switch (process.env.REACT_APP_TYPE) {
  case EnvironmentType.DEV:
    return '0x8E3C2dd20fd0Eb046685FA0c1d651e63b1DaA7dA'
  case EnvironmentType.STAGE:
    return '0x0b58740aC8587EB352f18dee6960031Be4Beb00F'
  case EnvironmentType.PROD:
    return '0x73C4D4CB2e0EbF834936E5a6DA1DF9dE8624e95c'
  default:
    return ''
  }
}
