import './index.scss'
import {
  GreyDAIIcon,
  GreyETHIcon,
  GreyFTMIcon,
  GreyUSDCIcon,
  GreyWBTCIcon,
  SwapIcon,
  USDVIcon,
  YearnIcon,
} from '../../../../components/Icons'
import useMobile from '../../../../hooks/useMobile'

const InstructionBlock = () => {
  const { windowWidth } = useMobile()
  
  return (
    <div className="instruction-block">
      <div className="instruction-title subtitle">
        How to get USDV
      </div>
      <div className="instruction-option">
        {windowWidth > 599 && <div className="option-one" />}
        <div className="instruction-item">
          <div className="vault-image" />
          <div className="instruction">
            <div className="name">
              1. Using a vault
            </div>
            <div className="description">
              Deposit collateral to a vault to mint USDV
            </div>
            <div className="instruction-list-item">
              <div className="step">1</div>
              <div className="description">
                <span>First step</span>
                <span>Create a vault</span>
              </div>
            </div>
            <div className="instruction-list-item">
              <div className="step">2</div>
              <div className="description">
                <span>Second step</span>
                <span>Deposit a collateral</span>
              </div>
            </div>
            <div className="grey-token-icons">
              <GreyWBTCIcon />
              <GreyFTMIcon />
              <GreyETHIcon />
              <YearnIcon />
            </div>
            <div className="instruction-list-item">
              <div className="step">3</div>
              <div className="description">
                <span>Third step</span>
                <span>Mint USDV</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="instruction-option second">
        {windowWidth > 599 && <div className="option-two" />}
        <div className="instruction-item">
          <div className="instruction">
            <div className="name">
              2. Using swap protocol
            </div>
            <div className="description">
              Directly swap USDC or DAI  for USDV
            </div>
            <div className="instruction-list-item">
              <div className="item">
                <span>Swap USDC or DAI for USDV</span>
                <div className="icons">
                  <GreyUSDCIcon />
                  <GreyDAIIcon />
                  <SwapIcon />
                  <USDVIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="shield-image" />
        </div>
      </div>
    </div>
  )
}

export default InstructionBlock
