import { useEffect, useState } from 'react'

const getMobile = () => window.innerWidth < 550
const getTablet = () => window.innerWidth < 744
const getWindowWidth = () => window.innerWidth

export default function useMobile() {
  const [mobile, setMobile] = useState(getMobile())
  const [tablet, setTablet] = useState(getTablet())
  const [windowWidth, setWindowWidth] = useState(getWindowWidth())

  useEffect(() => {
    const onResize = () => {
      setMobile(getMobile())
      setTablet(getTablet())
      setWindowWidth(getWindowWidth())
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return {
    mobile,
    tablet,
    windowWidth,
  }
}
