import { ExampleNotification } from '../../../../components'

import {
  StageFour,
  StageOne,
  StageThree,
  StageTwo,
} from './components'

import './index.scss'

const LiquidationBLock = () => {
  return (
    <div className="liquidation-block">
      <div className="block-title">
        Liquidation principle
      </div>
      <div className="grey-20-text">
        If the amount of collateral in the vault drops below the minimum collateral ratio,
        the algorithm will suggest a 50% cover and a return of up to 10% of the collateral.
      </div>
      <ExampleNotification>
        <div className="text">
          User deposits 0.04 BTC ≈ $900 to borrow 300 USDV at a minimum collateral level of 150%. If the collateral
          level drops below 150%, the vault becomes undercollateralized. After that, a smart contract launches a
          liquidation process. In this case, USDV protocol will offer to redeem 50% of the loan with a 10% bonus fee.
          Any user can repay 150 USDV loan (50% of 300 USDV loan) and get back 150 USD and 15 USD collateral (10%) as
          bonus fee in BTC.
          After all these operations the vault becomes overcollateralized again.
        </div>
      </ExampleNotification>
      <StageOne />
      <StageTwo />
      <StageThree />
      <StageFour />
    </div>
  )
}

export default LiquidationBLock
