import Icon, { TIconProps } from '../Icon'

const ArrowIcon = (props: TIconProps) => {
  return (
    <Icon iconName="arrow-icon" { ...props }>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8136 7.57471C12.434 7.95437 11.8043 7.95437 11.4246 7.57471L8.19284 4.34292L4.96103 7.57472C4.58137
          7.95439 3.95168 7.95439 3.57201 7.57472C3.19234 7.19505 3.19234 6.56536 3.57201 6.1857L7.49832
          2.25938C7.87799 1.87971 8.50768 1.87971 8.88735 2.25938L12.8136 6.18568C13.1933 6.56535 13.1933 7.19504
          12.8136 7.57471Z"
          fill="#90A7E8"
        />
        <path
          d="M8.87827 14.1218C8.70232 14.2977 8.46156 14.4089 8.18375 14.4089C7.64666 14.4089 7.20218 13.9644 7.20218
          13.4273L7.20218 2.94478C7.20218 2.40769 7.64666 1.9632 8.18375 1.9632C8.72084 1.9632 9.16534 2.4077 9.16534
          2.94479L9.16533 13.4273C9.17459 13.6958 9.05421 13.9459 8.87827 14.1218Z"
          fill="#90A7E8"
        />
      </svg>
    </Icon>
  )
}

export default ArrowIcon
