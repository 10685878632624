export enum NetworkType {
  fantom = 'fantom',
  ethereum = 'homestead',
  bsc = 'bsc',
}

export enum EnvironmentType {
  DEV = 'DEV',
  STAGE = 'STAGE',
  PROD = 'PROD',
  PRE_PROD = 'PRE_PROD',
}

export enum Tokens {
  ftm = 'ftm',
  wftm = 'wftm',
  yvwftm = 'yvwftm',
  eth = 'eth',
  weth = 'weth',
  yvweth = 'yvweth',
  usdv = 'usdv',
  pear = 'pear',
  wbtc = 'wbtc',
  yvwbtc = 'yvwbtc',
  dai = 'dai',
  usdc = 'usdc',
  boo = 'boo',
  moobooboo = 'moobooboo',
  bnb = 'bnb',
  moovenusbnb = 'moovenusbnb',
  hai = 'hai',
}
