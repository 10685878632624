import { Abi } from 'abitype'

import { PoolConfig } from '../../types'
import { getUSDVAddress } from '../../../../utils/getUSDVAddress'
import { NetworkType } from '../../../../enums'
import { ERC20 } from '../../abi/ERC20'
import { ERC20StablecoinEnhanced } from '../../abi/ERC20StablecoinEnhanced'
import { getHAIPoolAddress } from '../../../../utils/getHAIPoolAddress'

export const HAIxUSDV: PoolConfig = {
  collateral: 'HAI',
  contractAddress: getHAIPoolAddress(),
  network: NetworkType.bsc,
  abi: ERC20StablecoinEnhanced as Abi,
  decimals: 18,
  erc20Address: '0xaA9E582e5751d703F85912903bacADdFed26484C',
  erc20Abi: ERC20 as Abi,
  stablecoinAddress: getUSDVAddress(NetworkType.bsc),
  stablecoinAbi: ERC20 as Abi,
  strategy: false,
}
