import { AllPoolsType } from '../types'
import { EnvironmentType } from '../../../enums'

import { FTMxUSDV } from './fantom/FTMxUSDV'
import { WBTCxUSDV } from './fantom/WBTCxUSDV'
import { WETHxUSDV } from './fantom/WETHxUSDV'
import { WETHxUSDV_STRATEGY_YEARN as FANTOM_WETHxUSDV_STRATEGY_YEARN } from './fantom/WETHxUSDV_STRATEGY_YEARN'
import { WETHxUSDV_STRATEGY_YEARN as ETHEREUM_WETHxUSDV_STRATEGY_YEARN } from './ethereum/WETHxUSDV_STRATEGY_YEARN'
import { WFTMxUSDV_STRATEGY_YEARN } from './fantom/WFTMxUSDV_STRATEGY_YEARN'
import { BOOxUSDV_STRATEGY_BEEFY } from './fantom/BOOxUSDV_STRATEGY_BEEFY'
import { WBTCxUSDV_STRATEGY_YEARN } from './ethereum/WBTCxUSDV_STRATEGY_YEARN'
import { BNBxUSDV_STRATEGY_BEEFY } from './bsc/BNBxUSDV_STRATEGY_BEEFY'
import { BNBxUSDV } from './bsc/BNBxUSDV'
import { HAIxUSDV } from './bsc/HAIxUSDV'

const allPools: AllPoolsType = {
  fantom: process.env.REACT_APP_TYPE !== EnvironmentType.DEV
    ? [
      FTMxUSDV,
      // WBTCxUSDV,
      // WETHxUSDV,
      WFTMxUSDV_STRATEGY_YEARN,
      BOOxUSDV_STRATEGY_BEEFY,
    ]
    : [
      FTMxUSDV,
      // WBTCxUSDV,
      // WETHxUSDV,
      FANTOM_WETHxUSDV_STRATEGY_YEARN,
      WFTMxUSDV_STRATEGY_YEARN,
      BOOxUSDV_STRATEGY_BEEFY,
    ],
  homestead: [
    ETHEREUM_WETHxUSDV_STRATEGY_YEARN,
    WBTCxUSDV_STRATEGY_YEARN,
  ],
  bsc: [
    BNBxUSDV,
    HAIxUSDV,
    BNBxUSDV_STRATEGY_BEEFY,
  ],
}

export default allPools
