import { Abi } from 'abitype'

import { NetworkType } from '../../../../enums'
import { StrategyVault } from '../../abi/StrategyVault'
import { ERC20 } from '../../abi/ERC20'
import { PoolConfig, StrategyVaultsType } from '../../types'
import { getBooPoolAddress } from '../../../../utils/getBooPoolAddress'
import { getUSDVAddress } from '../../../../utils/getUSDVAddress'

export const BOOxUSDV_STRATEGY_BEEFY: PoolConfig = {
  collateral: 'BOO',
  contractAddress: getBooPoolAddress(),
  network: NetworkType.fantom,
  abi: StrategyVault as Abi,
  decimals: 18,
  erc20Address: '0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
  erc20Abi: ERC20 as Abi,
  stablecoinAddress: getUSDVAddress(NetworkType.fantom),
  stablecoinAbi: ERC20 as Abi,
  strategy: true,
  strategyType: StrategyVaultsType.Beefy,
}
