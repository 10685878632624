import { useEffect, useState } from 'react'
import classNames from 'classnames'

import { NavItem } from '../../types'
import useMobile from '../../../../hooks/useMobile'
import './index.scss'

interface INavigationBlock {
  navItems: NavItem[]
}

const NavigationBlock = ({ navItems }: INavigationBlock) => {
  const [leftPosition, setLeftPosition] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)

  const { windowWidth } = useMobile()

  const getLeftPosition = () => {
    const mainBlock = document.querySelector('.how-it-works') as HTMLDivElement
    const rootElement = document.querySelector('#root') as HTMLDivElement
    const addingNumber = window.innerWidth > 1275 ? 20 : 12

    setLeftPosition(rootElement.offsetLeft + mainBlock.offsetLeft + mainBlock.clientWidth + addingNumber)
  }
  
  const activeItemVerification = (top: number, height: number): boolean => {
    const bottom = top + height + 100

    if (top < 200 && scrollPosition < 200) return true

    return scrollPosition >= top && scrollPosition <= bottom
  }

  const itemClickHandler = (to: number) => {
    const location = to < 200 ? 0 : to - 100

    window.scrollTo({
      top: location,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    getLeftPosition()
    
    const getScrollPosition = () => {
      if (windowWidth > 1229) {
        const scrollPosition = window.scrollY

        setScrollPosition(scrollPosition + 200)
      }
    }

    window.addEventListener('resize', getLeftPosition)
    window.addEventListener('scroll', getScrollPosition)
    return () => {
      window.removeEventListener('resize', getLeftPosition)
      window.removeEventListener('scroll', getScrollPosition)
    }
  }, [windowWidth])

  return (
    <>
      {leftPosition > 0 ? (
        <div
          className="navigation-block"
          style={{
            left: leftPosition,
          }}
        >
          {navItems.map((item, index) => (
            <div
              key={index}
              className={classNames(
                'navigation-item',
                activeItemVerification(
                  item.element?.offsetTop as number,
                  item.element?.clientHeight as number,
                ) && 'active',
              )}
              onClick={() => itemClickHandler(item.element?.offsetTop as number)}
            >
              {item.title}
            </div>
          ))}
        </div>
      ) : null}
    </>
  )
}

export default NavigationBlock
