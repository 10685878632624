import { getLink } from '../../../../../../utils/getLink'
import {
  GreyETHIcon,
  GreyFTMIcon,
  GreyUSDVIcon,
  GreyVaultIcon,
} from '../../../../../../components/Icons'
import './index.scss'

const GenerationSteps = () => {
  return (
    <div className="generation-steps">
      <div className="generation-step">
        <div className="list-item">
          <div className="step">
            1
          </div>
          <div className="description">
            <span>
              Create a vault
            </span>
            <span>
              User creates a vault at
              <a
                href={getLink()}
                target="_blank"
                rel="noreferrer"
              >
                app.usdv.com
              </a>
            </span>
          </div>
        </div>
        <div className="icons vault">
          <GreyVaultIcon />
        </div>
      </div>
      <div className="generation-step">
        <div className="list-item">
          <div className="step">
            2
          </div>
          <div className="description">
            <span>
              Deposit a collateral
            </span>
            <span>
              User deposits X ETH in a vault as collateral.
              For example, a user deposits 1 ETH, which at a current rate is worth $1,200
            </span>
          </div>
        </div>
        <div className="icons">
          <GreyFTMIcon />
          <GreyETHIcon />
        </div>
      </div>
      <div className="generation-step">
        <div className="list-item">
          <div className="step">
            3
          </div>
          <div className="description">
            <span>
              Mint USDV
            </span>
            <span>
              Users can mint X USDV with a 130% collateral ratio.
              If a user deposits 1 ETH at a rate of $1,200,
              they can borrow up to $1,200 / 130% = 923.076923 USDV
            </span>
          </div>
        </div>
        <div className="icons">
          <GreyUSDVIcon />
        </div>
      </div>
    </div>
  )
}

export default GenerationSteps
