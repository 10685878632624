import Icon, { TIconProps } from '../Icon'

const InfoIcon = (props: TIconProps) => {
  return (
    <Icon iconName="info-icon" { ...props }>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.81818 1C8.71591 1 7.81818 1.96603 7.81818 3.15217C7.81818 4.33832 8.71591 5.30435 9.81818
          5.30435C10.9205 5.30435 11.8182 4.33832 11.8182 3.15217C11.8182 1.96603 10.9205 1 9.81818 1ZM6.36364
          6.47826C6.16335 6.47826 6 6.65251 6 6.86957V9.21739C6 9.43444 6.16335 9.6087 6.36364
          9.6087H8.18182V15.8696H6.36364C6.16335 15.8696 6 16.0438 6 16.2609V18.6087C6 18.8257 6.16335 19 6.36364
          19H13.6364C13.8366 19 14 18.8257 14 18.6087V16.2609C14 16.0438 13.8366 15.8696 13.6364
          15.8696H11.8182V6.86957C11.8182 6.65251 11.6548 6.47826 11.4545 6.47826H6.36364Z"
          fill="white"
        />
      </svg>
    </Icon>
  )
}

export default InfoIcon
