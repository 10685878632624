import Icon, { TIconProps } from '../Icon'

const HAIIcon = (props: TIconProps) => {
  return (
    <Icon iconName="hai-icon" { ...props }>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="40"
          height="40"
          rx="20"
          fill="#0F1929"
        />
        <path d="M30.4009 11V15.908H28.8019V11H27.2028V15.908H25.6037V11H24.0047V15.908H16.0009V11H14.4019V15.908V17.5431H30.4009V19.1782H28.8019H27.2028H12.8V11H11.2009V19.181H9.59906V11H8V19.181V20.8161V28.9971H9.59906V24.0891H11.1981V28.9971H12.7972V24.0891H14.3963V28.9971H15.9953V24.0891H23.9963V28.9971H25.5953V24.0891V22.454H12.8H11.2009H9.59906V20.819H11.1981H12.7972H27.1972V29H28.7963V20.8161H30.3953V28.9971H32V20.8161V19.181V17.546V15.9109V11H30.4009Z" fill="#3EF2D0"/>
      </svg>
    </Icon>
  )
}

export default HAIIcon
