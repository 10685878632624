import Icon, { TIconProps } from '../Icon'

const YearnIcon = (props: TIconProps) => {
  return (
    <Icon iconName="yearn-icon" { ...props }>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.8334 24.0001C43.8334 34.9537 34.9537 43.8334 24.0001 43.8334C13.0464 43.8334 4.16675 34.9537 4.16675
          24.0001C4.16675 13.0464 13.0464 4.16675 24.0001 4.16675C34.9537 4.16675 43.8334 13.0464 43.8334 24.0001Z"
          fill="#4B65AE"
          stroke="#1C2B54"
          strokeWidth="3"
        />
        <path
          d="M15.1024 11.0435L13.4238 12.7538L17.5197 16.9262L21.6156
          21.0987V24.9826V28.8666H24.0024H26.3892V24.9826V21.0987L30.4751 16.9363L34.5611 12.774L32.872 11.0549L31.1831
          9.33572L27.6148 12.9609C25.6523 14.9548 24.0271 16.586 24.0033 16.586C23.9795 16.586 22.352 14.9542 20.3868
          12.9597C18.4215 10.9651 16.8061 9.33325 16.7972 9.33325C16.7883 9.33325 16.0256 10.1029 15.1024 11.0435Z"
          fill="#1C2B54"
        />
        <path
          d="M13.2101 21.0676C12.6759 22.2166 12.3665 23.2067 12.1672 24.4047C11.5976 27.83 12.498 31.3576 14.642
          34.1002C14.9958 34.5527 16.0367 35.6126 16.4779 35.9696C18.4639 37.5763 20.7248 38.4666 23.2609
          38.6402C24.778 38.744 26.2949 38.5414 27.7849 38.0359C32.0915 36.5748 35.2008 32.8035 35.8733 28.2255C36.132
          26.4639 35.9924 24.5795 35.4742 22.8416C35.2682 22.1506 34.8509 21.1154 34.6328 20.7543L34.5493
          20.6163L32.7333 22.4646C31.2685 23.9554 30.9217 24.3288 30.9404 24.3952C31.0901 24.9296 31.1696 25.4852
          31.1909 26.1467C31.2323 27.4284 31.0332 28.4644 30.5377 29.5464C29.5747 31.6499 27.6956 33.1512 25.3981
          33.6527C24.9992 33.7398 24.8525 33.7496 23.9821 33.7484C23.128 33.7471 22.9603 33.7358 22.5865
          33.6535C20.3087 33.152 18.5172 31.7327 17.5065 29.6288C17.0497 28.678 16.8429 27.798 16.8117 26.6723C16.7892
          25.8582 16.8333 25.3659 16.9861 24.7251L17.0845 24.313L15.2744 22.4688C14.2789 21.4544 13.4535 20.6246
          13.4402 20.6246C13.4269 20.6246 13.3233 20.8239 13.2101 21.0676Z"
          fill="#1C2B54"
        />
      </svg>
    </Icon>
  )
}

export default YearnIcon
