import { Abi } from 'abitype'

import { PoolConfig, StrategyVaultsType } from '../../types'
import { ERC20 } from '../../abi/ERC20'
import { getUSDVAddress } from '../../../../utils/getUSDVAddress'
import { NetworkType } from '../../../../enums'
import { StrategyVault } from '../../abi/StrategyVault'
import { getVenusBNBPoolAddress } from '../../../../utils/getVenusBNBPoolAddress'

export const BNBxUSDV_STRATEGY_BEEFY: PoolConfig = {
  collateral: 'BNB',
  contractAddress: getVenusBNBPoolAddress(),
  network: NetworkType.bsc,
  abi: StrategyVault as Abi,
  decimals: 18,
  erc20Address: '0x6be4741ab0ad233e4315a10bc783a7b923386b71',
  erc20Abi: ERC20 as Abi,
  stablecoinAddress: getUSDVAddress(NetworkType.bsc),
  stablecoinAbi: ERC20 as Abi,
  strategy: true,
  strategyType: StrategyVaultsType.Beefy,
}
