import Icon, { TIconProps } from '../Icon'

const BlueArrowRangeIcon = (props: TIconProps) => {
  return (
    <Icon iconName="blue-arrow-icon" { ...props }>
      <svg
        width="24"
        height="150"
        viewBox="0 0 24 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 150L23.7735 140L12.2265 140L18 150ZM18 0L12.2265 10L23.7735 10L18 0ZM19 141L19 9L17 9L17 141L19 141Z"
          fill="#3B9CF6"
        />
        <circle
          cx="1"
          cy="1"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="5"
          cy="1"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="9"
          cy="1"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="13"
          cy="1"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="1"
          cy="149"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="5"
          cy="149"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="9"
          cy="149"
          r="1"
          fill="#3B9CF6"
        />
        <circle
          cx="13"
          cy="149"
          r="1"
          fill="#3B9CF6"
        />
      </svg>
    </Icon>
  )
}

export default BlueArrowRangeIcon
