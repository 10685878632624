import { EnvironmentType } from '../enums'

export const getFTMPoolAddress = (): string => {
  switch (process.env.REACT_APP_TYPE) {
  case EnvironmentType.DEV:
    return '0x0D60EE1E6219D958c228c28B158D9dA4Bd3d0Ff2'
  case EnvironmentType.STAGE:
    return '0xf214bf219353f87Eb2E385135F76232edEeaF656'
  case EnvironmentType.PROD:
    return '0x45317963330689A658a88D2EF131A668287034c8'
  default:
    return ''
  }
}
