import { EnvironmentType } from '../enums'

export const getLink = (): string => {
  const environment = process.env.REACT_APP_TYPE
  switch (environment) {
  case EnvironmentType.DEV:
    return 'https://app.usdv-react.ibisweb3.dev/'
  case EnvironmentType.STAGE:
    return 'https://stage.app.react.usdv.com/'
  case EnvironmentType.PROD:
    return 'https://app.usdv.com/'
  default:
    return ''
  }
}
