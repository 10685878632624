import { HTMLAttributes } from 'react'

import { getLink } from '../../utils/getLink'

const LaunchAppBtn = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <a
      className="btn-primary"
      target="_blank"
      rel="noreferrer"
      onClick={props.onClick as () => void}
    >
      Coming soon
    </a>
  )
}

export default LaunchAppBtn
