import useMobile from '../../../../hooks/useMobile'
import {
  GreyDAIIcon,
  GreyETHIcon,
  GreyFTMIcon,
  GreyUSDCIcon,
  GreyWBTCIcon,
  SwapIcon,
  USDVIcon,
  YearnIcon,
} from '../../../../components/Icons'
import './index.scss'

const HowToGetBlock = () => {
  const { windowWidth } = useMobile()
  
  return (
    <div className="how-to-get-block">
      <div className="block-title">
        How to get USDV
      </div>
      <div className="top-section">
        {windowWidth > 599 && <div className="option-one" />}
        <div className="section-title">
          {windowWidth > 899 ? (
            <>
              <div className="grey-title">
                First option
              </div>
              <div className="title">
                To get USDV
              </div>
            </>
          ) : (
            <div className="title">
              1. Using a vault
            </div>
          )}
          <div className="description">
            Deposit collateral to a vault to mint USDV
          </div>
        </div>
        <div className="instruction">
          <div className="instruction-item">
            <div className="step">1</div>
            <div className="description">
              <span>First step</span>
              <span>Create a vault</span>
            </div>
          </div>
          <div className="instruction-item">
            <div className="step">2</div>
            <div className="description">
              <span>Second step</span>
              <span>Deposit a collateral</span>
            </div>
          </div>
          <div className="grey-token-icons">
            <GreyWBTCIcon />
            <GreyFTMIcon />
            <GreyETHIcon />
            <YearnIcon />
          </div>
          <div className="instruction-item">
            <div className="step">3</div>
            <div className="description">
              <span>Third step</span>
              <span>Mint USDV</span>
            </div>
          </div>
          <div className="vault-image" />
        </div>
      </div>
      <div className="bottom-section">
        {windowWidth > 599 && <div className="option-two" />}
        <div className="section-title">
          {windowWidth > 899 ? (
            <>
              <div className="grey-title">
                Second option
              </div>
              <div className="title">
                To get USDV
              </div>
            </>
          ) : (
            <div className="title">
              2. Using swap protocol
            </div>
          )}
          <div className="description">
            Swap USDC or DAI for USDV
          </div>
        </div>
        <div className="instruction">
          <div className="instruction-item">
            <span>Swap USDC or DAI for USDV</span>
            <div className="icons">
              <GreyDAIIcon />
              <GreyUSDCIcon />
              <SwapIcon />
              <USDVIcon />
            </div>
          </div>
          <div className="shield-image" />
        </div>
      </div>
    </div>
  )
}

export default HowToGetBlock
