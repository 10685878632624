import Icon, { TIconProps } from '../Icon'

const DAIIcon = (props: TIconProps) => {
  return (
    <Icon iconName="dai-icon" { ...props }>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 2.14307C29.8631 2.14307 37.8571 10.1387 37.8571 20.0002C37.8571 29.8633 29.8631 37.8574 20
          37.8574C10.1384 37.8574 2.14282 29.8625 2.14282 20.0002C2.14282 10.1387 10.1384 2.14307 20 2.14307Z"
          fill="#F5AC37"
        />
        <path
          d="M20.658 21.2611H27.4434C27.5881 21.2611 27.6564 21.2611 27.6668 21.0715C27.7223 20.3812 27.7223 19.6869
          27.6668 18.9958C27.6668 18.8616 27.6001 18.8062 27.4547 18.8062H13.9505C13.7834 18.8062 13.7384 18.8616
          13.7384 19.0183V21.0048C13.7384 21.2611 13.7384 21.2611 14.006 21.2611H20.658ZM26.9091 16.4846C26.9283 16.434
          26.9283 16.3786 26.9091 16.3287C26.7957 16.082 26.6615 15.8466 26.5057 15.6256C26.271 15.2479 25.9946 14.9
          25.6796 14.5874C25.5309 14.3985 25.3589 14.229 25.1661 14.0851C24.2002 13.2631 23.0519 12.6813 21.8176
          12.3888C21.1948 12.249 20.5584 12.1823 19.9203 12.1879H13.9272C13.7601 12.1879 13.7376 12.2546 13.7376
          12.4V16.3617C13.7376 16.5288 13.7376 16.5738 13.9497 16.5738H26.8287C26.8287 16.5738 26.9404 16.5513 26.9629
          16.4846H26.9082H26.9091ZM26.9091 23.5826C26.7194 23.5617 26.5282 23.5617 26.3385 23.5826H13.9618C13.7946
          23.5826 13.7384 23.5826 13.7384 23.806V27.6793C13.7384 27.8577 13.7384 27.9027 13.9618 27.9027H19.676C19.9493
          27.9236 20.2225 27.9043 20.4901 27.8472C21.3194 27.7878 22.135 27.6078 22.9129 27.3113C23.1957 27.2132
          23.4689 27.0854 23.7269 26.932H23.8048C25.1444 26.2353 26.2324 25.1408 26.9187 23.7972C26.9187 23.7972
          26.9966 23.6284 26.9091 23.5843V23.5826ZM11.4956 29.9108V29.8441V27.2437V26.3622V23.7393C11.4956 23.5939
          11.4956 23.5722 11.3172 23.5722H8.89521C8.76101 23.5722 8.70557 23.5722 8.70557
          23.3938V21.2732H11.2947C11.4393 21.2732 11.4956 21.2732 11.4956 21.0835V18.9854C11.4956 18.8512
          11.4956 18.8182 11.3172 18.8182H8.89521C8.76101 18.8182 8.70557 18.8182 8.70557 18.6398V16.6759C8.70557
          16.5529 8.70557 16.52 8.88396 16.52H11.2834C11.4506 16.52 11.4956 16.52 11.4956 16.3078V10.2922C11.4956
          10.1138 11.4956 10.0688 11.719 10.0688H20.0891C20.6966 10.093 21.3001 10.1597 21.8971 10.2697C23.1274 10.4972
          24.3095 10.9367 25.3903 11.5643C26.1071 11.9862 26.7668 12.4957 27.3542 13.0823C27.7962 13.5411 28.1948
          14.0377 28.5484 14.5665C28.8995 15.1025 29.1912 15.6754 29.4202 16.2741C29.4484 16.43 29.5978 16.5353 29.7537
          16.5087H31.7514C32.0078 16.5087 32.0078 16.5087 32.019 16.7546V18.5852C32.019 18.7636 31.9523 18.8086 31.7731
          18.8086H30.2327C30.0768 18.8086 30.0318 18.8086 30.043 19.0095C30.1041 19.6893 30.1041 20.3715 30.043
          21.0514C30.043 21.241 30.043 21.2635 30.256 21.2635H32.0182C32.0962 21.364 32.0182 21.4644 32.0182
          21.5657C32.0295 21.695 32.0295 21.826 32.0182 21.9554V23.3062C32.0182 23.4959 31.9628 23.5521 31.7948
          23.5521H29.6854C29.5384 23.524 29.3953 23.618 29.3616 23.7643C28.8593 25.0701 28.0558 26.2409 27.0175
          27.1795C26.6382 27.521 26.2397 27.8424 25.8234 28.1389C25.3766 28.3961 24.9419 28.6637 24.4838
          28.8758C23.6409 29.2551 22.757 29.5348 21.8497 29.7123C20.9883 29.8666 20.1148 29.9365 19.2381
          29.9245H11.4924V29.9132L11.4956 29.9108Z"
          fill="#FEFEFD"
        />
      </svg>
    </Icon>
  )
}

export default DAIIcon
