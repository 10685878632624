import { useEffect, useState } from 'react'

import useMobile from '../../hooks/useMobile'

import {
  CollateralizationBlock,
  GenerateBlock,
  HowToGetBlock,
  InteractingBlock,
  LiquidationBLock,
  NavigationBlock,
  PegMaintainedBlock,
  CommissionBlock,
} from './components'
import './index.scss'

const HowItWorksModule = () => {
  const [generateBlock, setGenerateBlock] = useState<HTMLElement | null>(null)
  const [interactingBlock, setInteractingBlock] = useState<HTMLElement | null>(null)
  const [collateralizationBlock, setCollateralizationBlock] = useState<HTMLElement | null>(null)
  const [liquidationBlock, setLiquidationBlock] = useState<HTMLElement | null>(null)
  const [pegMaintainedBlock, setPegMaintainedBlock] = useState<HTMLElement | null>(null)
  const [howToGetBlock, setHowToGetBlock] = useState<HTMLElement | null>(null)
  const [commissionBlock, setCommissionBlock] = useState<HTMLElement | null>(null)

  const { windowWidth } = useMobile()

  const NavItems = [
    {
      title: 'How to generate USDV',
      element: generateBlock,
    },
    {
      title: 'Protocol Interacting',
      element: interactingBlock,
    },
    {
      title: 'Collateral principal',
      element: collateralizationBlock,
    },
    {
      title: 'Liquidation principal',
      element: liquidationBlock,
    },
    {
      title: 'Peg maintained',
      element: pegMaintainedBlock,
    },
    {
      title: 'How to get USDV',
      element: howToGetBlock,
    },
    {
      title: 'Commission',
      element: commissionBlock,
    },
  ]

  useEffect(() => {
    setGenerateBlock(document.querySelector('.generate-block') as HTMLElement)
    setInteractingBlock(document.querySelector('.interacting-block') as HTMLElement)
    setCollateralizationBlock(document.querySelector('.collateralization-block') as HTMLElement)
    setLiquidationBlock(document.querySelector('.liquidation-block') as HTMLElement)
    setPegMaintainedBlock(document.querySelector('.peg-maintained-block') as HTMLElement)
    setHowToGetBlock(document.querySelector('.how-to-get-block') as HTMLElement)
    setCommissionBlock(document.querySelector('.commission-block') as HTMLElement)
  }, [])
  
  return (
    <div className="how-it-works">
      {windowWidth > 1229 && <NavigationBlock navItems={NavItems} />}
      <GenerateBlock />
      <InteractingBlock />
      <CollateralizationBlock />
      <LiquidationBLock />
      <PegMaintainedBlock />
      <HowToGetBlock />
      <CommissionBlock />
    </div>
  )
}

export default HowItWorksModule
