import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { Footer, Header, UpButton } from '../index'
import { FooterMarginBottom } from '../Footer'

const RootLayout = () => {
  const [buttonVisible, setButtonVisible] = useState(false)
  const [buttonFixed, setButtonFixed] = useState(true)
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [location.pathname])

  useEffect(() => {
    const buttonAppearHandler = () => {
      const footer = document.querySelector('.footer') as HTMLDivElement
      const body = document.getElementById('root') as HTMLDivElement

      const scrollPosition = window.scrollY
      const windowHeight = window.innerHeight
      const absoluteButtonPosition = body.clientHeight - (footer.clientHeight + FooterMarginBottom)

      if (scrollPosition > (windowHeight / 2)) {
        setButtonVisible(true)
      } else {
        setButtonVisible(false)
      }

      if (scrollPosition + windowHeight >= absoluteButtonPosition) {
        setButtonFixed(false)
      } else {
        setButtonFixed(true)
      }
    }

    window.addEventListener('scroll', buttonAppearHandler)
    return () => window.removeEventListener('scroll', buttonAppearHandler)
  }, [])

  return (
    <>
      <Header />
      <main>
        <Outlet />
        {buttonVisible && <UpButton fixed={buttonFixed} />}
      </main>
      <Footer />
    </>
  )
}

export default RootLayout
