import { createContext, useContext, ReactNode } from 'react'
import { observer } from 'mobx-react'

import { useStatistics, IStatisticsContext } from '../hooks/useStatistics'

type Props = {
  children: ReactNode;
}

export const StatisticsContext = createContext<IStatisticsContext | undefined>(undefined)

export const StatisticsProvider = observer((props: Props) => {
  const myHookData = useStatistics()

  return (
    <StatisticsContext.Provider value={ myHookData }>
      { props.children }
    </StatisticsContext.Provider>
  )
})

export const useStatisticsContext = () => {
  const statisticsContext = useContext(StatisticsContext)

  if (!statisticsContext) {
    throw new Error('DashboardContext must be defined')
  }

  return statisticsContext
}
