import { configureChains, createConfig } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { fantom, mainnet, bsc } from 'wagmi/chains'

import { getFirstRPCURL } from '../utils/getFirstRPCURL'
import { getSecondRPCURL } from '../utils/getSecondRPCURL'

const { publicClient, webSocketPublicClient } = configureChains(
  [fantom, mainnet, bsc],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: getFirstRPCURL(chain.network.toLowerCase()),
      }),
    }),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: getSecondRPCURL(chain.network.toLowerCase()),
      }),
    }),
  ],
)

export const config = createConfig({
  publicClient,
  webSocketPublicClient,
})
