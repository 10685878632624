import Icon, { TIconProps } from '../Icon'

const PaperclipIcon = (props: TIconProps) => {
  return (
    <Icon iconName="paperclip-icon" { ...props }>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.33 21.3399C11.24 21.3399 10.15 20.9299 9.32004 20.0999C7.66004 18.4399 7.66004 15.7499 9.32004
          14.0899L11.8 11.6199C12.09 11.3299 12.57 11.3299 12.86 11.6199C13.15 11.9099 13.15 12.3899 12.86
          12.6799L10.38 15.1499C9.31004 16.2199 9.31004 17.9699 10.38 19.0399C11.45 20.1099 13.2 20.1099 14.27
          19.0399L18.16 15.1499C19.34 13.9699 19.99 12.3999 19.99 10.7299C19.99 9.05986 19.34 7.48986 18.16
          6.30986C15.72 3.86986 11.76 3.86986 9.32004 6.30986L5.08004 10.5499C4.09004 11.5399 3.54004 12.8599 3.54004
          14.2599C3.54004 15.6599 4.09004 16.9799 5.08004 17.9699C5.37004 18.2599 5.37004 18.7399 5.08004
          19.0299C4.79004 19.3199 4.31004 19.3199 4.02004 19.0299C2.75004 17.7499 2.04004 16.0599 2.04004
          14.2599C2.04004 12.4599 2.74004 10.7599 4.02004 9.48986L8.26004 5.24986C11.28 2.22986 16.2 2.22986 19.22
          5.24986C20.68 6.70986 21.49 8.65986 21.49 10.7299C21.49 12.7999 20.68 14.7499 19.22 16.2099L15.33
          20.0999C14.5 20.9299 13.42 21.3399 12.33 21.3399Z"
          fill="white"
        />
      </svg>
    </Icon>
  )
}

export default PaperclipIcon
