import { Abi } from 'abitype'

import { PoolConfig } from '../../types'
import { NetworkType } from '../../../../enums'
import { ERC20 } from '../../abi/ERC20'
import { Stablecoin } from '../../abi/Stablecoin'
import { getFTMPoolAddress } from '../../../../utils/getFTMPoolAddress'
import { getUSDVAddress } from '../../../../utils/getUSDVAddress'

export const FTMxUSDV: PoolConfig = {
  collateral: 'FTM',
  contractAddress: getFTMPoolAddress(),
  abi: Stablecoin as Abi,
  network: NetworkType.fantom,
  decimals: 18,
  erc20Address: '',
  erc20Abi: [] as Abi,
  stablecoinAddress: getUSDVAddress(NetworkType.fantom),
  stablecoinAbi: ERC20 as Abi,
  strategy: false,
}
