import { EnvironmentType } from '../enums'

export const getBooPoolAddress = () => {
  switch (process.env.REACT_APP_TYPE) {
  case EnvironmentType.DEV:
    return '0xd6C20d949Db8aba3488141B337d93604cE6Ba097'
  case EnvironmentType.STAGE:
    return '0x70bFD6a5a63Bb0b01B9D8876425D2a7f2bBC482F'
  case EnvironmentType.PROD:
    return '0xabe671bced7df40434db43f198fd725f177a8582'
  default:
    return ''
  }
}
