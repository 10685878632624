import Icon, { TIconProps } from '../Icon'

const BNBIcon = (props: TIconProps) => {
  return (
    <Icon iconName="bnb-icon" { ...props }>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4800_70150)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 0C31.0465 0 40 8.95353 40 20C40 31.0465 31.0465 40 20 40C8.95353 40 0 31.0465 0 20C0 8.95353
            8.95353 0 20 0Z"
            fill="#F0B90B"
          />
          <path
            d="M10.992 19.9999L11.0064 25.2883L15.5 27.9325V31.0287L8.3766 26.8508V18.4534L10.992 19.9999ZM10.992
            14.7114V17.7931L8.375 16.245V13.1633L10.992 11.6152L13.6218 13.1633L10.992 14.7114ZM17.3766 13.1633L19.9936
            11.6152L22.6234 13.1633L19.9936 14.7114L17.3766 13.1633Z"
            fill="white"
          />
          <path
            d="M12.883 24.1922V21.096L15.5 22.6441V25.7258L12.883 24.1922ZM17.3766 29.0415L19.9936 30.5896L22.6234
            29.0415V32.1232L19.9936 33.6713L17.3766 32.1232V29.0415ZM26.3766 13.1633L28.9936 11.6152L31.6234
            13.1633V16.245L28.9936 17.7931V14.7114L26.3766 13.1633ZM28.9936 25.2883L29.008 19.9998L31.625
            18.4518V26.8492L24.5016 31.0271V27.9309L28.9936 25.2883Z"
            fill="white"
          />
          <path
            d="M27.117 24.1923L24.5 25.726V22.6443L27.117 21.0962V24.1923Z"
            fill="white"
          />
          <path
            d="M27.117 15.8078L27.1314 18.9039L22.625 21.5482V26.8494L20.008 28.3831L17.391 26.8494V21.5482L12.8846
            18.9039V15.8078L15.5128 14.2597L19.992 16.9168L24.4984 14.2597L27.1282 15.8078H27.117ZM12.883
            10.5209L19.9936 6.32861L27.117 10.5209L24.5 12.069L19.9936 9.41195L15.5 12.069L12.883 10.5209Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_4800_70150">
            <rect
              width="40"
              height="40"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  )
}

export default BNBIcon
