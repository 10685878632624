import {
  DottedLineIcon,
  BlueArrowRangeIcon,
  MobileBlueArrowRangeIcon,
} from '../../../../../../components/Icons'
import useMobile from '../../../../../../hooks/useMobile'

const StageThree = () => {
  const { windowWidth } = useMobile()

  return (
    <div className="stages third example-block">
      <div className="top-section">
        <div className="description">
          <div>Stage 3</div>
          <span>
            The liquidation process started.
          </span>
          <span>
            50% of the debt (150 USDV) is  liquidated.
          </span>
          <span>
            Collateral decreased by 0.01665 BTC (debt + reward for liquidator + commission system fee)<sup>1</sup>.
          </span>
        </div>
        <div className="value-legend">
          <div className="item">
            <div className="color-indicator collateral" />
            <div className="explanation">Collateral value</div>
          </div>
          <div className="item">
            <div className="color-indicator loan" />
            <div className="explanation">Loan value</div>
          </div>
          <div className="item">
            <div className="color-indicator fee" />
            <div className="explanation">1% Commission system fee</div>
          </div>
          <div className="item">
            <div className="color-indicator reward" />
            <div className="explanation">Liquidator's reward</div>
          </div>
          <div className="item">
            <div className="color-indicator liquidated" />
            <div className="explanation">Debt liquidated</div>
          </div>
        </div>
      </div>
      <div className="visual-representation">
        <div className="collateral">
          <div className="value-notice">
            {windowWidth > 837 ? (
              <span>
                1% Commission System fee
              </span>
            ) : (
              <span>
                1% Commission<br />
                System fee<br />
              </span>
            )}
          </div>
          <div className="value-notice">
            <span>
              0.040 BTC or $400<sup>1</sup>
            </span>
          </div>
          <div className="value-notice">
            {windowWidth > 837 ? (
              <span>
                10% reward for Liquidator
              </span>
            ) : (
              <span>
                10% reward for<br />
                Liquidator<br />
              </span>
            )}
          </div>
          <DottedLineIcon />
          <DottedLineIcon className="lower" />
          {windowWidth > 669 ? <BlueArrowRangeIcon /> : <MobileBlueArrowRangeIcon />}
          <div className="value">
            0.040 BTC {windowWidth <= 669 && <br />} or $900
          </div>
          <div className="column">
            <div className="yellow-layer" />
            <div className="green-layer" />
            <div className="blue-layer" />
            <div className="rate">
              <span>133.3%</span>
              <span>of the Loan<sup>2</sup></span>
            </div>
          </div>
          <div className="name">
            Collateral
          </div>
        </div>
        <div className="loan">
          <div className="value-notice">
            <span>
              150 USDV is going<br />
              to be liquidated
            </span>
          </div>
          <DottedLineIcon />
          <div className="value">
            300 USDV {windowWidth <= 669 && <br />} or $300
          </div>
          <div className="column">
            <div className="dark-yellow-layer" />
          </div>
          <div className="name">
            Loan
          </div>
        </div>
      </div>
      <div className="footnote">
        <div className="note">
          <span>1) (150 USDV + 150 USDV x 10% + 150 USDV x 1%) / $10,000 = 0.01665 BTC</span>
        </div>
        <div className="note">
          <span>2) Collateralization ratio = ($400 / $300) x 100% = 133.3%</span>
        </div>
      </div>
    </div>
  )
}

export default StageThree
