import Icon, { TIconProps } from '../Icon'

const GreyFTMIcon = (props: TIconProps) => {
  return (
    <Icon iconName="grey-ftm-icon" { ...props }>
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M66.6429 36C66.6429 52.9236 52.9236 66.6429 36 66.6429C19.0765 66.6429 5.35718 52.9236 5.35718 36C5.35718
          19.0765 19.0765 5.35718 36 5.35718C52.9236 5.35718 66.6429 19.0765 66.6429 36Z"
          fill="#4B65AE"
          stroke="#1C2B54"
          strokeWidth="3"
        />
        <path
          d="M26.5572 47.7377V38.3571L35.8993 43.8066L36.0001 43.8654L36.1009 43.8066L45.443 38.3571V47.7377L36.0001
          53.2461L26.5572 47.7377ZM23.8479 22.567L23.7465 22.6245V22.741V49.058V49.1745L23.8479 49.232L35.9015
          56.0623L36.0001 56.1182L36.0987 56.0623L48.1523 49.232L48.2537 49.1745V49.058V22.741V22.6245L48.1523
          22.567L36.0987 15.7366L36.0001 15.6808L35.9015 15.7366L23.8479 22.567ZM45.443 33.6428L38.8077 29.7722L45.443
          25.9017V33.6428ZM26.5572 33.6428V25.9017L33.1925 29.7722L26.5572 33.6428ZM37.4055 39.8705V32.1294L44.0407
          35.9999L37.4055 39.8705ZM34.5947 32.1294V39.8705L27.9595 35.9999L34.5947 32.1294ZM36.0001 18.755L44.0407
          23.541L36.0001 28.1356L27.9595 23.541L36.0001 18.755Z"
          fill="#1C2B54"
          stroke="#1C2B54"
          strokeWidth="0.4"
        />
      </svg>
    </Icon>
  )
}

export default GreyFTMIcon
