import React, { memo } from 'react'
import classNames from 'classnames'

interface IBaseIconProps {
  children: JSX.Element,
  iconName: string,
  className?: string,
}

export type TIconProps = {
  onClick? (event: React.MouseEvent<HTMLDivElement>): void,
  className?: string,
} & React.HTMLAttributes<HTMLDivElement>

const Icon = (props: IBaseIconProps & TIconProps) => {
  return (
    <div
      className={classNames(
        'icon',
        props.iconName,
        props.className,
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  )
}

export default memo(Icon)
