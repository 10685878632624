import { EnvironmentType, NetworkType } from '../enums'

export const getUSDVAddress = (chain: string): string => {
  switch(chain) {
  case NetworkType.fantom:
    switch (process.env.REACT_APP_TYPE) {
    case EnvironmentType.DEV:
      return '0x2A624cBf110a82090feF95ca2729570273B54EFE'
    case EnvironmentType.STAGE:
      return '0xa9827790a96609B405f7680d6bD2427210F61361'
    case EnvironmentType.PROD:
      return '0x953E94CaF91a1E32337d0548B9274f337920eDFA'
    default:
      return ''
    }
  case NetworkType.bsc:
    switch (process.env.REACT_APP_TYPE) {
    case EnvironmentType.DEV:
      return '0xb0D7D2aAa4EBcB7e744dc247e90e9b0EB06C1cFb'
    case EnvironmentType.STAGE:
      return '0x896f04508c0088d5F38EC92B06BB0BBCba0833fc'
    case EnvironmentType.PROD:
      return '0x953E94CaF91a1E32337d0548B9274f337920eDFA'
    default:
      return ''
    }
  case NetworkType.ethereum:
    switch (process.env.REACT_APP_TYPE) {
    case EnvironmentType.DEV:
      return '0x68529CEf97A6497841754439E71e104911772392'
    case EnvironmentType.STAGE:
      return '0xd4ffbA8910129C2C0477F90f26b118d5Aa6739A2'
    case EnvironmentType.PROD:
      return '0x20b3B07E9C0e37815e2892Ab09496559F57C3603'
    default:
      return ''
    }
  default:
    return ''
  }
}
