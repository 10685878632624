import BigNumber from 'bignumber.js'
import { Abi } from 'abitype'

import { NetworkType } from '../../enums'

export type Pool = {
  network: string,
  originalName: string,
  contractAddress: string,
  collateral: string,
  decimals?: number,
  lockedInVaults: BigNumber,
  usdvMinted: BigNumber,
  ethPriceSource: BigNumber,
  strategy: boolean,
  strategyType?: StrategyVaultsType,
}

export type PoolConfig = {
  collateral: string,
  contractAddress: string,
  abi: Abi,
  network: NetworkType,
  decimals: number,
  erc20Address?: string,
  erc20Abi: Abi,
  stablecoinAddress: string,
  stablecoinAbi: Abi,
  strategy: boolean,
  strategyType?: StrategyVaultsType,
}

export type AllPoolsType = {
  [key: string]: PoolConfig[],
}

export type ChartData = {
  value: number,
  name: string,
}

export enum StrategyVaultsType {
  Yearn = 'Yearn',
  Beefy = 'Beefy'
}

export type StringContractResult = {
  result: string,
  status: 'success' | 'reverted',
}

export type ContractCallResult = {
  result: number | bigint,
  status: 'success' | 'reverted',
}

export type MulticallReturnData = [
  originalName: StringContractResult,
  lockedInVaultsBN: ContractCallResult,
  stablecoinDecimalsBN: ContractCallResult,
  ethPriceSourceBN: ContractCallResult,
  supplyBN: ContractCallResult,
  erc20Decimals?: ContractCallResult,
]
