import {
  DiscordIcon,
  DocumentIcon,
  InfoIcon,
  MediumIcon,
  TwitterIcon,
} from '../Icons'
import './index.scss'
import {Link} from "react-router-dom";

const Socials = () => {
  const LINKS = [
    {
      icon: <TwitterIcon />,
      href: 'https://twitter.com/USDV_COM',
    },
    /*{
      icon: <DiscordIcon />,
      href: '',
    },
    {
      icon: <MediumIcon />,
      href: '',
    },*/
    {
      icon: <DocumentIcon />,
      href: 'https://docs.usdv.com/usdv',
    },
  ]
  
  return (
    <div className="socials">
      {LINKS.map((link, index) => (
        <a
          key={index}
          className="social-link"
          href={link.href}
          target="_blank"
          rel="noreferrer"
        >
          {link.icon}
        </a>
      ))}
      <Link
        to="/how-it-works"
        className="social-link"
      >
        <InfoIcon />
      </Link>
    </div>
  )
}

export default Socials
