import { NetworkType } from '../enums'

export const getFirstRPCURL = (chain: string): string => {
  switch(chain) {
  case NetworkType.fantom:
    return 'https://rpc.ftm.tools/'
  case NetworkType.ethereum:
    return 'https://eth.llamarpc.com'
  case NetworkType.bsc:
    return 'https://bsc.publicnode.com'
  default:
    return ''
  }
}
