import { Abi } from 'abitype'

import { PoolConfig, StrategyVaultsType } from '../../types'
import { getUSDVAddress } from '../../../../utils/getUSDVAddress'
import { getWETHPoolStrategyAddress } from '../../../../utils/getWETHPoolStrategyAddress'
import { NetworkType } from '../../../../enums'
import { ERC20 } from '../../abi/ERC20'
import { StrategyVault } from '../../abi/StrategyVault'

export const WETHxUSDV_STRATEGY_YEARN: PoolConfig = {
  collateral: 'wETH',
  contractAddress: getWETHPoolStrategyAddress(NetworkType.ethereum),
  network: NetworkType.ethereum,
  abi: StrategyVault as Abi,
  decimals: 18,
  erc20Address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  erc20Abi: ERC20 as Abi,
  stablecoinAddress: getUSDVAddress(NetworkType.ethereum),
  stablecoinAbi: ERC20 as Abi,
  strategy: true,
  strategyType: StrategyVaultsType.Yearn,
}
