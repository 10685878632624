import classNames from 'classnames'

import { PLATFORMS } from '../../constants'
import useMobile from '../../../../hooks/useMobile'
import './index.scss'


const PlatformsBlock = () => {
  const { windowWidth } = useMobile()

  return (
    <div className="platforms-block">
      <div className="subtitle">
        USDV works with
      </div>

      {
        (windowWidth > 599) && (
          <>
            <div className="platforms">
              {PLATFORMS.slice(0, 4).map((platform, i) => (
                <div
                  key={ platform.name }
                  className="platform"
                >
                  <div
                    className={classNames(
                      'platform-icon',
                      platform.icon,
                    )}
                  />
                  <div className="platform-name">
                    {platform.name}
                  </div>
                </div>
              ))}
            </div>

            <div
              className="platforms"
              style={{
                width: windowWidth > 1100 ? '50%' : '66%',
                maxWidth: 839,
              }}
            >
              { PLATFORMS.slice(4, PLATFORMS.length + 1).map((platform, i) => (
                <div
                  key={ platform.name }
                  className="platform"
                >
                  <div
                    className={ classNames(
                      'platform-icon',
                      platform.icon,
                    ) }
                  />
                  <div className="platform-name">
                    { platform.name }
                  </div>
                </div>
              )) }
            </div>
          </>
        )
      }

      {
        windowWidth < 599 && (
          <>
            {PLATFORMS.map((platform, i) => {
              return i % 2 === 0
                ? (
                  <div key={i} className="platforms">
                    <div
                      key={PLATFORMS[i].name}
                      className="platform"
                    >
                      <div
                        className={classNames(
                          'platform-icon',
                          PLATFORMS[i].icon,
                        )}
                      />
                      <div className="platform-name">
                        {PLATFORMS[i].name}
                      </div>
                    </div>


                    {
                      PLATFORMS[i + 1] && (
                        <div
                          key={PLATFORMS[i + 1].name}
                          className="platform"
                        >
                          <div
                            className={ classNames(
                              'platform-icon',
                              PLATFORMS[i + 1].icon,
                            ) }
                          />
                          <div className="platform-name">
                            {PLATFORMS[i + 1].name}
                          </div>
                        </div>
                      )
                    }
                  </div>
                )
                : null
            })}
          </>
        )
      }
    </div>
  )
}

export default PlatformsBlock
