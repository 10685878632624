import Icon, { TIconProps } from '../Icon'

const DottedLineIcon = (props: TIconProps) => {
  return (
    <Icon iconName="dotted-line-icon" { ...props }>
      <svg
        width="14"
        height="2"
        viewBox="0 0 14 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="1"
          cy="1"
          r="1"
          fill="#3E569A"
        />
        <circle
          cx="5"
          cy="1"
          r="1"
          fill="#3E569A"
        />
        <circle
          cx="9"
          cy="1"
          r="1"
          fill="#3E569A"
        />
        <circle
          cx="13"
          cy="1"
          r="1"
          fill="#3E569A"
        />
      </svg>
    </Icon>
  )
}

export default DottedLineIcon
