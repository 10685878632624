import { Link } from 'react-router-dom'

import { LaunchAppBtn } from '../index'
import { SocialsArrowIcon } from "../Icons"
import './index.scss'

interface INavActions {
  onClick?: () => void,
}

const NavActions = ({ onClick }: INavActions ) => {
  return (
    <div className="nav-actions">
      <div className="router-links">
        <Link
          className="action"
          to="/how-it-works"
          onClick={onClick}
        >
          How it works
          <SocialsArrowIcon />
        </Link>
        <Link
          className="action"
          to="https://docs.usdv.com/usdv"
          onClick={onClick}
        >
          Docs
          <SocialsArrowIcon />
        </Link>
        <Link
          className="action"
          to="mailto:support@usdv.com"
          onClick={onClick}
          target={"_self"}
        >
          Contact us
        </Link>
      </div>

      <LaunchAppBtn />
    </div>
  )
}

export default NavActions
