import { Link } from 'react-router-dom'

import { USDVIcon } from '../Icons'
import './index.scss'

const Logo = () => {
  return (
    <Link 
      className="logo"
      to="/"
    >
      <USDVIcon />
      USDV
    </Link>
  )
}

export default Logo
