import Icon, { TIconProps } from '../Icon'

const GreyETHIcon = (props: TIconProps) => {
  return (
    <Icon iconName="grey-eth-icon" { ...props }>
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.0296 28.5283L6.0297 28.5279C10.1548 11.9724 26.9207 1.89743 43.471 6.02436L43.471 6.02438C60.0253
          10.1514 70.0988 26.9202 65.9717 43.4749C61.8447 60.0283 45.0788 70.1026 28.5233 65.9746C11.975 61.8476
          1.90067 45.0807 6.0296 28.5283Z"
          fill="#4B65AE"
          stroke="#1C2B54"
          strokeWidth="3"
        />
        <path
          d="M35.9979 46.3391L49.8629 38.211L35.9979 57.5948L22.1399 38.2086L35.9979 46.3344V46.3391ZM35.9979
          12.78L49.8536 35.6032L35.9979 43.7337L22.1399 35.6032L35.9979 12.78Z"
          fill="#1C2B54"
        />
        <path
          d="M36.0015 12.7795V29.3465L49.8571 35.6062L36.0015 12.7795Z"
          fill="#1C2B54"
        />
        <path
          opacity="0.996"
          d="M35.9979 12.78L22.1399 35.6031L35.9979 29.3434V12.78Z"
          fill="#1C2B54"
        />
        <path d="M36.0015 46.342V57.5988L49.8641 38.2114L36.0015 46.342Z" fill="#1C2B54"/>
        <path d="M35.9977 57.5964V46.342L22.1396 38.2114L35.9977 57.5964Z" fill="#1C2B54"/>
        <path d="M36.0015 43.7337L49.8571 35.6032L36.0015 29.3506V43.7337Z" fill="#1C2B54"/>
        <path d="M22.1396 35.6032L35.9977 43.7337V29.3506L22.1396 35.6032Z" fill="#1C2B54"/>
      </svg>
    </Icon>
  )
}

export default GreyETHIcon
