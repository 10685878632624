import Icon, { TIconProps } from '../Icon'

const GreyDAIIcon = (props: TIconProps) => {
  return (
    <Icon iconName="grey-dai-icon" { ...props }>
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1764_33215)">
          <path
            d="M36 1.5C55.0555 1.5 70.4999 16.9475 70.4999 36C70.4999 55.0556 55.0556 70.4999 36 70.4999C16.9475
            70.4999 1.5 55.0539 1.5 36C1.5 16.9476 16.9476 1.5 36 1.5Z"
            fill="#4B65AE"
            stroke="#1C2B54"
            strokeWidth="3"
          />
          <path
            d="M37.3268 38.5418H51.0062C51.2978 38.5418 51.4355 38.5418 51.4566 38.1595C51.5684 36.7679 51.5684
            35.3682 51.4566 33.975C51.4566 33.7045 51.3221 33.5927 51.0289 33.5927H23.8045C23.4676 33.5927 23.3769
            33.7045 23.3769 34.0204V38.0251C23.3769 38.5418 23.3769 38.5418 23.9163 38.5418H37.3268ZM49.9289
            28.9125C49.9678 28.8104 49.9678 28.6986 49.9289 28.5982C49.7005 28.1008 49.4299 27.6262 49.1157
            27.1807C48.6426 26.4193 48.0853 25.7178 47.4503 25.0876C47.1506 24.7069 46.8039 24.3651 46.4151
            24.0751C44.4678 22.4178 42.1528 21.2449 39.6645 20.6553C38.409 20.3734 37.1259 20.2389 35.8396
            20.2503H23.7576C23.4206 20.2503 23.3752 20.3847 23.3752 20.6779V28.6646C23.3752 29.0016 23.3752 29.0923
            23.8029 29.0923H49.7669C49.7669 29.0923 49.9921 29.0469 50.0374 28.9125H49.9273H49.9289ZM49.9289
            43.2221C49.5466 43.1799 49.161 43.1799 48.7787 43.2221H23.8272C23.4903 43.2221 23.3769 43.2221 23.3769
            43.6724V51.4809C23.3769 51.8405 23.3769 51.9313 23.8272 51.9313H35.3472C35.898 51.9734 36.4488 51.9345
            36.9882 51.8195C38.6601 51.6996 40.3044 51.3367 41.8726 50.7389C42.4428 50.5413 42.9936 50.2837 43.5136
            49.9743H43.6708C46.3714 48.5697 48.5649 46.3633 49.9483 43.6546C49.9483 43.6546 50.1055 43.3144 49.9289
            43.2253V43.2221ZM18.8554 55.9797V55.8452V50.6028V48.8257V43.538C18.8554 43.2447 18.8554 43.201 18.4958
            43.201H13.613C13.3425 43.201 13.2307 43.201 13.2307 42.8414V38.5661H18.4504C18.742 38.5661 18.8554 38.5661
            18.8554 38.1838V33.954C18.8554 33.6834 18.8554 33.617 18.4958 33.617H13.613C13.3425 33.617 13.2307 33.617
            13.2307 33.2573V29.298C13.2307 29.0502 13.2307 28.9838 13.5904 28.9838H18.4277C18.7647 28.9838 18.8554
            28.9838 18.8554 28.5561V16.4286C18.8554 16.069 18.8554 15.9783 19.3058 15.9783H36.1798C37.4046 16.0269
            38.6212 16.1613 39.8249 16.3833C42.3051 16.8417 44.6882 17.7279 46.8671 18.9931C48.3121 19.8436 49.6422
            20.8707 50.8264 22.0533C51.7174 22.9784 52.5209 23.9795 53.2337 25.0455C53.9417 26.126 54.5298 27.2811
            54.9915 28.488C55.0482 28.8023 55.3495 29.0145 55.6638 28.9611H59.6911C60.2079 28.9611 60.2079 28.9611
            60.2306 29.4568V33.1472C60.2306 33.5068 60.0961 33.5976 59.7349 33.5976H56.6293C56.315 33.5976 56.2243
            33.5976 56.247 34.0026C56.3701 35.3731 56.3701 36.7485 56.247 38.119C56.247 38.5013 56.247 38.5467 56.6763
            38.5467H60.229C60.3861 38.7492 60.229 38.9517 60.229 39.1558C60.2516 39.4166 60.2516 39.6807 60.229
            39.9415V42.6648C60.229 43.0471 60.1172 43.1605 59.7786 43.1605H55.5261C55.2296 43.1038 54.9412 43.2933
            54.8732 43.5882C53.8607 46.2207 52.2407 48.5811 50.1476 50.4732C49.383 51.1618 48.5794 51.8098 47.7403
            52.4075C46.8395 52.9259 45.9631 53.4654 45.0397 53.8931C43.3403 54.6577 41.5583 55.2215 39.7293
            55.5795C37.9926 55.8906 36.2317 56.0315 34.4642 56.0072H18.8489V55.9845L18.8554 55.9797Z"
            fill="#1C2B54"
          />
        </g>
        <defs>
          <clipPath id="clip0_1764_33215">
            <rect
              width="72"
              height="72"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  )
}

export default GreyDAIIcon
