import Icon, { TIconProps } from '../Icon'

const BitcoinIcon = (props: TIconProps) => {
  return (
    <Icon iconName="bitcoin-icon" { ...props }>
      <svg 
        width="64" 
        height="64" 
        viewBox="0 0 64 64" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="32"
          cy="32"
          r="32"
          fill="white"
        />
        <path
          d="M52.1853 13.4316L50.428 15.1889C54.6211 19.7744 56.9464 25.7631 56.9464 31.9767C56.9464 38.1904 54.6211
          44.1791 50.428 48.7646L52.1853 50.5219C56.8485 45.4637 59.4375 38.836 59.4375 31.9562C59.4375 25.0765 56.8485
          18.4488 52.1853 13.3906V13.4316Z"
          fill="#5A5564"
        />
        <path
          d="M15.2297 13.5837C19.8152 9.39055 25.8039 7.06523 32.0175 7.06523C38.2312 7.06523 44.2199 9.39055 48.8054
          13.5837L50.5626 11.8264C45.5045 7.1632 38.8767 4.57422 31.997 4.57422C25.1173 4.57422 18.4895 7.1632 13.4314
          11.8264L15.2297 13.5837Z"
          fill="#5A5564"
        />
        <path
          d="M13.5837 48.7822C9.39552 44.1977 7.07326 38.2127 7.07326 32.0031C7.07326 25.7936 9.39552 19.8086 13.5837
          15.2241L11.8264 13.4668C7.1632 18.5249 4.57422 25.1527 4.57422 32.0324C4.57422 38.9121 7.1632 45.5399
          11.8264 50.598L13.5837 48.7822Z"
          fill="#5A5564"
        />
        <path
          d="M48.7819 50.4043C44.1964 54.5974 38.2077 56.9228 31.9941 56.9228C25.7805 56.9228 19.7918 54.5974 15.2063
          50.4043L13.449 52.1616C18.5071 56.8248 25.1349 59.4138 32.0146 59.4138C38.8943 59.4138 45.5221 56.8248
          50.5802 52.1616L48.7819 50.4043Z"
          fill="#5A5564"
        />
        <path
          d="M43.0942 26.1365C42.7428 22.4696 39.5797 21.2396 35.5789 20.8647V15.8154H32.4861V20.7709C31.6719 20.7709
          30.8402 20.7709 30.0142 20.7709V15.8154H26.9449V20.8998H20.6714V24.2094C20.6714 24.2094 22.9558 24.1683
          22.9207 24.2094C23.3326 24.164 23.746 24.2804 24.0738 24.5339C24.4016 24.7874 24.6182 25.1583 24.678
          25.5683V39.4859C24.6692 39.6306 24.6315 39.7719 24.5671 39.9017C24.5027 40.0315 24.4129 40.1471 24.3031
          40.2416C24.1953 40.3379 24.0692 40.4114 23.9323 40.4577C23.7954 40.504 23.6506 40.5221 23.5065 40.511C23.5475
          40.5462 21.2571 40.511 21.2571 40.511L20.6714
          44.2072H26.8863V49.3735H29.9791V44.2833H32.451V49.3501H35.5497V44.2423C40.7746 43.926 44.418 42.6373 44.8749
          37.7462C45.244 33.8099 43.393 32.0527 40.4349 31.3439C42.2332 30.4594 43.3461 28.8193 43.0942
          26.1365ZM38.7596 37.137C38.7596 40.9796 32.1757 40.5403 30.0787 40.5403V33.7221C32.1757 33.7279 38.7596
          33.1246 38.7596 37.137ZM37.3245 27.5306C37.3245 31.0452 31.8301 30.6176 30.0845 30.6176V24.4202C31.8301
          24.4202 37.3245 23.8696 37.3245 27.5306Z"
          fill="#F09242"
        />
        <path
          d="M31.9942 64C25.6657 63.9989 19.4796 62.1213 14.2181 58.6046C8.95664 55.088 4.85603 50.0902 2.43475
          44.2432C0.0134704 38.3962 -0.619743 31.9625 0.615171 25.7557C1.85009 19.5488 4.89767 13.8475 9.3726
          9.3726C13.8475 4.89767 19.5488 1.85009 25.7557 0.615171C31.9625 -0.619743 38.3962 0.0134704 44.2432
          2.43475C50.0902 4.85603 55.088 8.95664 58.6046 14.2181C62.1213 19.4796 63.9989 25.6657 64 31.9942C64.0008
          36.1974 63.1735 40.3597 61.5653 44.2432C59.9571 48.1267 57.5996 51.6553 54.6274 54.6274C51.6553 57.5996
          48.1267 59.9571 44.2432 61.5653C40.3597 63.1735 36.1974 64.0008 31.9942 64ZM31.9942 2.49535C26.1637 2.49998
          20.4654 4.23301 15.6196 7.47539C10.7738 10.7178 6.99803 15.324 4.76946 20.7117C2.5409 26.0995 1.95962
          32.0271 3.09908 37.7451C4.23854 43.4632 7.0476 48.7151 11.1712 52.8371C15.2948 56.9591 20.5478 59.766
          26.2664 60.9032C31.9849 62.0404 37.9122 61.4568 43.2991 59.2261C48.686 56.9954 53.2907 53.2178 56.5311
          48.3707C59.7716 43.5236 61.5024 37.8247 61.5047 31.9942C61.5062 28.1189 60.7439 24.2813 59.2612
          20.7009C57.7786 17.1205 55.6047 13.8674 52.864 11.1278C50.1232 8.38809 46.8693 6.21553 43.2883
          4.73431C39.7073 3.25309 35.8694 2.49227 31.9942 2.49535Z"
          fill="#282138"
        />
      </svg>
    </Icon>
  )
}

export default BitcoinIcon
